import { useCallback, useMemo, useState } from 'react'
import type { LanguageCode } from '@sevenrooms/core/api'
import type { LanguageStrings, ReservationWidgetSettingsLanguageString, VenueLanguage } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { DescriptionColumn, OtherLanguagesTable, type OtherLanguagesTableColumn } from '../../components'
import { generalLanguagesTableMessages } from './GeneralLanguagesTable.locales'

export interface GeneralLanguagesTableProps {
  languageStrings: LanguageStrings
  onChange: (value: LanguageStrings) => void
  enablePrivateEventsBanner: boolean
  languageOptions: SelectOption<LanguageCode>[]
  defaultLanguage: VenueLanguage
}

export function GeneralLanguagesTable({
  languageStrings,
  onChange,
  enablePrivateEventsBanner,
  languageOptions,
  defaultLanguage,
}: GeneralLanguagesTableProps) {
  const { formatMessage } = useLocales()

  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions[0]?.id)

  const onLanguageStringChange = useCallback(
    (value: string, language: LanguageCode, stringToChange: ReservationWidgetSettingsLanguageString) => {
      const updatedLanguageStrings = {
        ...languageStrings,
        [language]: {
          ...languageStrings[language],
          [stringToChange]: value,
        },
      }
      onChange(updatedLanguageStrings)
    },
    [languageStrings, onChange]
  )

  const tableData = useMemo(() => {
    let result: OtherLanguagesTableColumn[] = [
      {
        id: 'resWidgetCrossSellVenueLongDescription',
        description: (
          <DescriptionColumn primary={formatMessage(generalLanguagesTableMessages.otherLanguagesTableVenueDescriptionColumnLabel)} />
        ),
        defaultLanguage: languageStrings[defaultLanguage?.value].resWidgetCrossSellVenueLongDescription,
        selectedLanguage: selectedLanguage ? languageStrings[selectedLanguage]?.resWidgetCrossSellVenueLongDescription : '',
      },
      {
        id: 'resWidgetTitle',
        description: <DescriptionColumn primary={formatMessage(generalLanguagesTableMessages.otherLanguagesTableWidgetTitleColumnLabel)} />,
        defaultLanguage: languageStrings[defaultLanguage?.value].resWidgetTitle,
        selectedLanguage: selectedLanguage ? languageStrings[selectedLanguage]?.resWidgetTitle : '',
      },
      {
        id: 'resWidgetSpecialAttentionLabel',
        description: (
          <DescriptionColumn
            primary={formatMessage(generalLanguagesTableMessages.otherLanguagesTableSpecialAttentionLabelColumnLabel)}
            secondary={formatMessage(generalLanguagesTableMessages.otherLanguagesTableSpecialAttentionLabelColumnDescription)}
          />
        ),
        defaultLanguage: languageStrings[defaultLanguage?.value].resWidgetSpecialAttentionLabel,
        selectedLanguage: selectedLanguage ? languageStrings[selectedLanguage]?.resWidgetSpecialAttentionLabel : '',
      },
      {
        id: 'resWidgetSpecialAttentionInfoBody',
        description: (
          <DescriptionColumn
            primary={formatMessage(generalLanguagesTableMessages.otherLanguagesTableSpecialAttentionBodyColumnLabel)}
            secondary={formatMessage(generalLanguagesTableMessages.otherLanguagesTableSpecialAttentionBodyColumnDescription)}
          />
        ),
        defaultLanguage: languageStrings[defaultLanguage?.value].resWidgetSpecialAttentionInfoBody,
        selectedLanguage: selectedLanguage ? languageStrings[selectedLanguage]?.resWidgetSpecialAttentionInfoBody : '',
      },
    ]
    if (enablePrivateEventsBanner) {
      result = [
        ...result,
        {
          id: 'resWidgetPrivateEventsBannerLabel',
          description: (
            <DescriptionColumn primary={formatMessage(generalLanguagesTableMessages.otherLanguagesTablePEBannerHeadlineLabel)} />
          ),
          defaultLanguage: languageStrings[defaultLanguage?.value].resWidgetPrivateEventsBannerLabel,
          selectedLanguage: selectedLanguage ? languageStrings[selectedLanguage]?.resWidgetPrivateEventsBannerLabel : '',
        },
        {
          id: 'resWidgetPrivateEventsBannerDescription',
          description: (
            <DescriptionColumn primary={formatMessage(generalLanguagesTableMessages.otherLanguagesTablePEBannerDescriptionLabel)} />
          ),
          defaultLanguage: languageStrings[defaultLanguage?.value].resWidgetPrivateEventsBannerDescription,
          selectedLanguage: selectedLanguage ? languageStrings[selectedLanguage]?.resWidgetPrivateEventsBannerDescription : '',
        },
        {
          id: 'resWidgetPrivateEventsBannerExploreButtonLabel',
          description: <DescriptionColumn primary={formatMessage(generalLanguagesTableMessages.otherLanguagesTablePEBannerButtonLabel)} />,
          defaultLanguage: languageStrings[defaultLanguage?.value].resWidgetPrivateEventsBannerExploreButtonLabel,
          selectedLanguage: selectedLanguage ? languageStrings[selectedLanguage]?.resWidgetPrivateEventsBannerExploreButtonLabel : '',
        },
      ]
    }
    return result
  }, [formatMessage, defaultLanguage, languageStrings, selectedLanguage, enablePrivateEventsBanner])

  return (
    <OtherLanguagesTable
      data={tableData}
      onChange={onLanguageStringChange}
      onSelectedLanguageChange={setSelectedLanguage}
      defaultLanguage={defaultLanguage}
      languageOptions={languageOptions}
      selectedLanguage={selectedLanguage as LanguageCode}
    />
  )
}
