import { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  type LanguageListData,
  useGetReservationWidgetSettingsV2Query,
  useGetVenueLanguagesQuery,
  useGetTestConfigurationQuery,
} from '@sevenrooms/core/api'
import { useForm } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Button, IconButton } from '@sevenrooms/core/ui-kit/form'
import { useLocalStorage } from '@sevenrooms/core/ui-kit/hooks'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { HStack, Flex, Window, notify, Box, Modal, ModalTitle, ModalHeader } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { IntroContent } from './IntroContent'
import { LaunchCompleteContent } from './LaunchCompleteContent'
import { LaunchWidgetContent } from './LaunchWidgetContent'
import { LoaderStep } from './LoadingStep'
import { newResWidgetBannerMessages } from './NewResWidgetBanner.messages'
import { OtherBrandSettingsStep } from './OtherBrandSettingsStep'
import { PreviewWidgetContent } from './PreviewWidgetContent'
import { useReservationSettingsForm } from './ReservationSettings.zod'
import { ReviewVenueDescriptionStep } from './ReviewVenueDescriptionStep'
import { UploadHeaderStep } from './UploadHeaderStep'

const HAS_DISPLAYED_WIDGET_SETUP_MODAL_KEY = 'hasDisplayedWidgetSetupModal'
const { venueUrlKey, venueId } = window.globalInit
const newResWidgetUrl = `https://${window.location.host}/explore/${venueUrlKey}/reservations/create/search`
const newResWidgetSettingsUrl = `/manager2/${venueUrlKey}/settings/widgets/reservation`
const paymentsSettingsUrl = `/manager2/${venueUrlKey}/settings/payment-integration/view/manager2/`

export function NewResWidgetBanner() {
  const { formatMessage } = useLocales()
  const {
    data: languageListData,
    isFetching: isLanguageListFetching,
    error: languageListError,
  } = useGetVenueLanguagesQuery({
    venueId,
  })
  const { data: settings, isFetching: isSettingsFetching, error: settingsError } = useGetReservationWidgetSettingsV2Query({ venueId })
  const { data: isPaymentConfigured, isFetching: isGetTestConfigurationFetching } = useGetTestConfigurationQuery({ venueId })
  const [hasDisplayedSetupModal, setHasDisplayedSetupModal] = useLocalStorage(false, HAS_DISPLAYED_WIDGET_SETUP_MODAL_KEY)
  const [shouldDisplaySetupModal, setShouldDisplaySetupModal] = useState(() => !hasDisplayedSetupModal)
  const [currentModalStep, setCurrentModalStep] = useState(0)
  const [isLaunchComplete, setIsLaunchComplete] = useState(false)

  const schema = useReservationSettingsForm()
  const rhForm = useForm(schema, {
    defaultValues: { ...settings?.general },
  })
  const { reset, field } = rhForm

  useEffect(() => {
    if (settings) {
      reset({ ...settings.general })
    }
  }, [reset, settings])

  useEffect(() => {
    if (languageListError || settingsError) {
      notify({ content: formatMessage(newResWidgetBannerMessages.error), type: 'error' })
    }
  }, [formatMessage, languageListError, reset, settings, settingsError])

  useEffect(() => {
    if (shouldDisplaySetupModal && !hasDisplayedSetupModal) {
      setHasDisplayedSetupModal(true)
    }
  }, [hasDisplayedSetupModal, setHasDisplayedSetupModal, shouldDisplaySetupModal])

  const closeModal = () => {
    setShouldDisplaySetupModal(false)
    setCurrentModalStep(0)
  }

  const preview = () => {
    window.open(newResWidgetUrl)
  }

  const goToNextStep = () => {
    setCurrentModalStep(currentModalStep + 1)
  }

  const goToPreviousStep = () => {
    setCurrentModalStep(currentModalStep - 1)
  }

  const goToTheFinalPage = (isPaymentConfigured: boolean) => (isPaymentConfigured ? goToNewSettingsPage : goToPaymentsSettingsPage)

  const goToPaymentsSettingsPage = () => {
    window.location.assign(paymentsSettingsUrl)
  }

  const goToNewSettingsPage = () => {
    window.location.assign(newResWidgetSettingsUrl)
  }

  const formSteps = [
    <UploadHeaderStep
      key="upload-header-step"
      stepIndex={1}
      onComplete={goToNextStep}
      onPrevious={goToPreviousStep}
      field={field.prop('headerImg')}
    />,
    <ReviewVenueDescriptionStep
      key="review-venue-description-step"
      stepIndex={2}
      onComplete={goToNextStep}
      onPrevious={goToPreviousStep}
      field={field.prop('languageStrings')}
      languageListData={languageListData as LanguageListData}
    />,
    <OtherBrandSettingsStep
      key="other-brand-settings-step"
      stepIndex={3}
      onComplete={goToNextStep}
      onPrevious={goToPreviousStep}
      rhForm={rhForm}
    />,
  ]

  const modalSteps = [
    <IntroContent key="intro-step" onClose={closeModal} onComplete={goToNextStep} />,
    <PreviewWidgetContent key="preview-step" onComplete={goToNextStep} onPreview={preview} onPrevious={goToPreviousStep} />,
    ...(isLanguageListFetching || isSettingsFetching || isGetTestConfigurationFetching ? [<LoaderStep key="loader-step" />] : formSteps),
    <LaunchWidgetContent
      key="launch"
      onComplete={() => {
        setIsLaunchComplete(true)
        goToNextStep()
      }}
      onPreview={preview}
      onPrevious={goToPreviousStep}
    />,
    <LaunchCompleteContent
      key="complete"
      onPreview={preview}
      onComplete={goToTheFinalPage(isPaymentConfigured || false)}
      isPaymentConfigured={isPaymentConfigured || false}
    />,
  ]

  const isLastStep = () => currentModalStep === modalSteps.length - 1

  return (
    <>
      <StyledBannerBackground p="xl" m="none lm" borderRadius="s">
        <Box mb="sm">
          <Text textStyle="h2">{formatMessage(newResWidgetBannerMessages.bannerTitle)}</Text>
        </Box>
        <Text textStyle="body1">{formatMessage(newResWidgetBannerMessages.bannerDescription)}</Text>
        <HStack spacing="s" mt="m">
          <Button
            variant="primary"
            data-test="sr-launch-button"
            onClick={() => {
              setShouldDisplaySetupModal(true)
            }}
          >
            {formatMessage(newResWidgetBannerMessages.bannerLaunchButton)}
          </Button>
          {/* Hack to make the secondary button white. Should be changed once GX-3514 is complete. */}
          <Box backgroundColor="primaryBackground" borderRadius="s">
            <Button variant="secondary" data-test="preview-button" onClick={preview}>
              {formatMessage(newResWidgetBannerMessages.bannerPreviewButton)}&nbsp;
              <Icon name="VMSWeb-open-in-new" size="lg" />
            </Button>
          </Box>
        </HStack>
      </StyledBannerBackground>
      {shouldDisplaySetupModal && (
        <Window active>
          <Modal
            ariaLabel={formatMessage(newResWidgetBannerMessages.modalLabel)}
            width={isLastStep() ? 'min-content' : '967px'}
            maxHeight="600px"
          >
            {isLastStep() ? (
              <ModalHeader
                onClose={() => {
                  if (isLaunchComplete) {
                    goToNewSettingsPage()
                  } else {
                    closeModal()
                  }
                }}
              >
                <ModalTitle
                  title={
                    isPaymentConfigured
                      ? formatMessage(newResWidgetBannerMessages.launchCompleteModalTitle)
                      : formatMessage(newResWidgetBannerMessages.launchCompleteModalTitleSetupPayment)
                  }
                />
              </ModalHeader>
            ) : (
              <HStack p="sm" borderBottomColor="borders" borderWidth="s">
                <Flex flex="1 1 auto" alignItems="center" justifyContent="center">
                  <Text textStyle="body1Bold">{formatMessage(newResWidgetBannerMessages.defaultModalTitle)}</Text>
                </Flex>
                <Flex flex="0 0 auto">
                  <IconButton
                    size="s"
                    borderType="none-round"
                    icon="VMSWeb-close"
                    iconColor="primaryIcons"
                    onClick={closeModal}
                    aria-label="close"
                    data-test="ui-kit-modal-close"
                  />
                </Flex>
              </HStack>
            )}
            {modalSteps[currentModalStep]}
          </Modal>
        </Window>
      )}
    </>
  )
}

const StyledBannerBackground = styled(Box)`
  background: linear-gradient(188deg, rgba(214, 169, 232, 0.5) 6.26%, rgba(119, 210, 249, 0.2) 95.58%);
`
