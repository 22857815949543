import React from 'react'
import { Tooltip as ReactTooltip } from 'react-lightweight-tooltip'

export const toolTipStyle = {
  tooltip: {
    padding: '8px',
    minWidth: '200px',
    textAlign: 'center',
    display: 'flex',
    borderRadius: '6px',
    flex: '1',
  },
  content: {
    flex: '1',
    padding: '0px',
    margin: '0px',
    textAlign: 'center',
  },
  wrapper: {
    zIndex: '',
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    cursor: 'pointer',
  },
}

const Tooltip = ({ content, children, minWidth = '200px' }) => (
  <ReactTooltip
    styles={{
      ...toolTipStyle,
      tooltip: { ...toolTipStyle.tooltip, minWidth },
    }}
    content={content}
  >
    {children}
  </ReactTooltip>
)

export default Tooltip
