import { combineReducers } from 'redux'
import { languageApi, reservationWidgetSettingsApiV2, paymentsSettingsApi } from '@sevenrooms/core/api'
import {
  GETTING_WIDGET_SETTINGS,
  GET_SUCCESS_WIDGET_SETTINGS,
  GET_FAIL_WIDGET_SETTINGS,
  POSTING_WIDGET_SETTINGS,
  POST_SUCCESS_WIDGET_SETTINGS,
  POST_FAIL_WIDGET_SETTINGS,
  UPDATE_FIELD,
  UPDATE_THEME,
  UPLOAD_PHOTO_PREVIEW,
  UPLOAD_PHOTO_SUCCESS,
  CROP_OPEN,
  CROP_PHOTO,
  CROP_CLOSE,
  CROP_DELETE,
  RESTORE_DEFAULT,
} from '../actions/ActionTypes'

const defaultThemes = {
  LIGHT: {
    fontsColorPrimary: '#000000',
    fontsColorSecondary: '#707070',
    fontsColorSummaryBar: '#FFFFFF',
    fontsColorButton: '#FFFFFF',
    fontsColorActionBar: '#000000',
    fontsColorLinks: '#50A0FD',
    fontsColorCheckoutInactive: '#9A9A9A',
    fontsColorCheckoutActive: '#000000',
    colorWidgetBackground: '#EEEEEE',
    colorWidgetBackgroundOpacity: 1.0,
    colorCellBackground: '#FFFFFF',
    colorCellBackgroundOpacity: 1.0,
    colorActionBarBackground: '#E3E8EC',
    colorActionBarBackgroundOpacity: 0.8,
    colorCheckoutCellBackground: '#FFFFFF',
    colorCheckoutCellBackgroundOpacity: 0.5,
    colorLines: '#EEEEEE',
    colorLinesOpacity: 0.5,
    colorError: '#F3376B',
    colorErrorOpacity: 1.0,
  },
  DARK: {
    fontsColorPrimary: '#FFFFFF',
    fontsColorSecondary: '#999999',
    fontsColorSummaryBar: '#FFFFFF',
    fontsColorButton: '#FFFFFF',
    fontsColorActionBar: '#FFFFFF',
    fontsColorLinks: '#50A0FD',
    fontsColorCheckoutInactive: '#DCDCDC',
    fontsColorCheckoutActive: '#FFFFFF',
    colorWidgetBackground: '#151616',
    colorWidgetBackgroundOpacity: 1.0,
    colorCellBackground: '#000000',
    colorCellBackgroundOpacity: 1.0,
    colorActionBarBackground: '#242526',
    colorActionBarBackgroundOpacity: 0.8,
    colorCheckoutCellBackground: '#FFFFFF',
    colorCheckoutCellBackgroundOpacity: 0.5,
    colorLines: '#1A1A1A',
    colorLinesOpacity: 0.5,
    colorError: '#F3376B',
    colorErrorOpacity: 1.0,
  },
}

const initialWidgetSettingsState = {
  ...defaultThemes.LIGHT,
  venueId: '',
  widgetTemplateTheme: 'LIGHT',
  font: 'Roboto',

  reservationHoldTimeMins: 5,
  reservationHoldEnabled: false,

  colorPrimary: '#324654',
  colorSummaryBar: '#011929',
  colorBackground: '#F4F4F4',
  colorBackgroundOpacity: 1.0,

  textButton: 'Reservations',
  colorButton: '#093A75',
  buttonId: 1,
  enableRequests: true,
  enableRequestsType: 'searched_time',
  enablePromoCodes: false,
  enableBonvoyLogin: true,
  enableDietaryRestrictions: true,
  requireDietaryTagGdprOptIn: false,
  enableSpecialOccasions: true,
  birthdayType: 'Hidden',
  postalCodeType: 'Hidden',
  salutationType: 'Hidden',

  saleStartNum: 30,
  saleStartType: 'NONE',
  minGuests: 1,
  maxGuests: 20,

  searchHaloDurationHours: 12,
  maxSearchResults: 8,

  lightTheme: {},
  darkTheme: {},

  // Text items
  textCustomPolicyHolderName: '',
  textCustomPrivacyPolicyLink: '',
  textCustomGdprPolicyLink: '',
}

const MIN_NUM_OF_GUESTS = 1
const MAX_NUM_OF_GUESTS = 99

const getNumOfGuests = function (numOfGuests) {
  let guests = numOfGuests
  if (guests < MIN_NUM_OF_GUESTS) {
    guests = MIN_NUM_OF_GUESTS
  } else if (guests > MAX_NUM_OF_GUESTS) {
    guests = MAX_NUM_OF_GUESTS
  }
  return guests
}

const widgetSettings = (state = initialWidgetSettingsState, action) => {
  switch (action.type) {
    case GET_SUCCESS_WIDGET_SETTINGS:
      return { ...state, ...action.widgetSettings }
    case RESTORE_DEFAULT:
      return { ...state, ...defaultThemes[state.widgetTemplateTheme] }
    case UPDATE_FIELD:
      if (action.fieldName === 'colorPrimary') {
        return { ...state, colorPrimary: action.value, colorButton: action.value, fontsColorLinks: action.value }
      }
      if (action.fieldName === 'hideCalendar') {
        window.HIDE_CALENDAR = action.value
      }
      if (action.fieldName === 'minGuests') {
        const minGuests = getNumOfGuests(action.value)
        return { ...state, minGuests, maxGuests: Math.max(state.maxGuests, minGuests) }
      }
      if (action.fieldName === 'maxGuests') {
        const maxGuests = getNumOfGuests(action.value)
        return { ...state, maxGuests, minGuests: Math.min(state.minGuests, maxGuests) }
      }
      if (action.fieldName === 'searchHaloDurationHours') {
        return { ...state, searchHaloDurationHours: action.value || 12 }
      }
      if (action.fieldName === 'maxSearchResults') {
        return { ...state, maxSearchResults: action.value || 8 }
      }
      return { ...state, [action.fieldName]: action.value }

    case UPDATE_THEME: {
      const theme = state.widgetTemplateTheme === 'LIGHT' ? state.lightTheme : state.darkTheme
      return {
        ...state,
        ...theme,
      }
    }
    case UPLOAD_PHOTO_PREVIEW:
      return { ...state, [`${action.imageType}ImgUrl`]: action.preview }
    case UPLOAD_PHOTO_SUCCESS:
      return {
        ...state,
        [`${action.imageType}ImgUrl`]: `/.h/download/${action.upload.url_key}`,
        [`${action.imageType}ImgBlobKey`]: action.upload.post_key,
        colorBackgroundOpacity: Math.min(state.colorBackgroundOpacity, 0.5),
      }
    case CROP_OPEN:
      return { ...state, isCropping: action.imageType }
    case CROP_PHOTO:
      return { ...state, [`${action.imageType}ImgCropData`]: action.coords }
    case CROP_CLOSE:
      return { ...state, isCropping: false }
    case CROP_DELETE:
      return {
        ...state,
        [`${action.imageType}ImgUrl`]: null,
        [`${action.imageType}ImgBlobKey`]: null,
        [`${action.imageType}ImgCropData`]: null,
        [`${action.imageType}ImgPhotoDict`]: null,
      }
    default:
      return state
  }
}

const initialAppState = {
  isGetting: false,
  isPosting: false,
  isFailure: false,
  errors: {}, // errors for form fields
}

const appState = (state = initialAppState, action) => {
  switch (action.type) {
    case UPDATE_FIELD:
      return {
        ...state,
        errors: { ...state.errors, [action.fieldName]: action.error },
      }
    case GETTING_WIDGET_SETTINGS:
      return { ...state, isGetting: true }
    case GET_SUCCESS_WIDGET_SETTINGS:
      return { ...state, isFailure: false, isGetting: false }
    case GET_FAIL_WIDGET_SETTINGS:
      return { ...state, isFailure: true, isGetting: false }
    case POSTING_WIDGET_SETTINGS:
      return { ...state, isPosting: true }
    case POST_SUCCESS_WIDGET_SETTINGS:
      return { ...state, isFailure: false, isPosting: false }
    case POST_FAIL_WIDGET_SETTINGS:
      return { ...state, isFailure: true, isPosting: false }
    default:
      return state
  }
}

const rootReducer = combineReducers({
  widgetSettings,
  appState,
  [reservationWidgetSettingsApiV2.reducerPath]: reservationWidgetSettingsApiV2.reducer,
  [languageApi.reducerPath]: languageApi.reducer,
  [paymentsSettingsApi.reducerPath]: paymentsSettingsApi.reducer,
})

export default rootReducer
