import { useState } from 'react'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { ModalBody, ModalFooter, ModalActions } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'

interface LaunchCompleteContentProp {
  onPreview: () => void
  onComplete: () => void
  isPaymentConfigured: boolean
}
export function LaunchCompleteContent({ onPreview, onComplete, isPaymentConfigured }: LaunchCompleteContentProp) {
  const [isDisabled, setIsDisabled] = useState(false)

  return (
    <>
      <ModalBody>
        <Text textStyle="body1">
          {isPaymentConfigured ? (
            <>
              You're all done! No other changes are needed — all old links will be redirected to the new widget. As always, you can make
              changes at any time.
            </>
          ) : (
            <>Connect a payment processor in minutes to enable online payments, like Upgrades and reservation deposits.</>
          )}
        </Text>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button variant="secondary" onClick={onPreview} disabled={isDisabled} data-test="view-live-widget">
            View Live Widget&nbsp;
            <Icon name="VMSWeb-open-in-new" size="lg" />
          </Button>
          <Button
            data-test="done-button"
            variant="primary"
            disabled={isDisabled}
            onClick={() => {
              setIsDisabled(true)
              onComplete()
            }}
          >
            Done
          </Button>
        </ModalActions>
      </ModalFooter>
    </>
  )
}
