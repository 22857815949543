import React, { Component } from 'react'

class Switch extends Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.props.toggle(this.props.name, !this.props.value)
  }

  render() {
    const disabled = !!this.props.disabled
    const wrap = {
      cursor: disabled ? 'arrow' : 'pointer',
      display: 'inline-block',
      ...this.props.style,
    }

    const s = this.props.value ? 'off' : 'on'
    const imageFileName = disabled ? `slide_${s}_disabled` : `slide_${s}`
    const src = `${MEDIA_URL}images/eventmanager/${imageFileName}.png`

    return (
      <div style={wrap} data-test={this.props.testId} className="switch" onClick={disabled ? () => {} : this.props.onClick || this.toggle}>
        <img src={src} style={{ width: 56 }} />
      </div>
    )
  }
}

export default Switch
