/* eslint-disable eqeqeq */
/* eslint-disable max-classes-per-file */
import _ from 'lodash'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import ReactTooltip from 'react-tooltip'

const helpers = {
  // eslint-disable-next-line consistent-return
  canuse(e) {
    if ($(e).hasClass('disabled')) {
      $(e).blur()
      return false
    }
  },
}

// This is double defined in
// the events app; should have common
// style imports
const informationStyle = {
  background: '#ccc',
  borderRadius: 12,
  color: '#fff',
  display: 'inline-block',
  fontFamily: 'Times',
  fontSize: 10,
  fontWeight: 'bold',
  hieght: 12,
  lineHieght: 12,
  marginLeft: 5,
  textAlign: 'center',
  width: 12,
}

class FormElement extends Component {
  constructor(props) {
    super(props)
    this.state = { value: props.value }
    this.onChangeHandler = this.onChangeHandler.bind(this)
  }

  onChangeHandler(e) {
    this.setState({ value: e.target.value })
    if (typeof this.props.onChangeHandler !== 'function') {
      return
    }
    this.props.onChangeHandler(e)
  }

  componentWillUpdate(props) {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.value = props.value
  }

  render() {
    let tip
    let tipPop

    if (this.props.tip) {
      tip = (
        <span data-for={`${this.props.name}tip`} data-tip={this.props.tip} style={informationStyle}>
          i
        </span>
      )
      tipPop = <ReactTooltip id={`${this.props.name}tip`} effect="solid" multiline />
    }

    const classNames = _.filter([
      'form-element',
      this.props.type,
      this.props.name,
      this.props.disabled ? 'disabled' : undefined,
      this.props.prelabel ? 'prelabeled' : undefined,
      this.props.parentClass,
      this.props.checked ? 'checked' : undefined,
      this.props.checkit && this.props.checkit == this.props.value ? 'checked' : undefined,
      !this.props.checkit && this.props.defaultradio ? 'checked' : undefined,
      this.props.money ? 'money' : undefined,
    ]).join(' ')
    const label = this.props.labelText ? this.props.labelText : ''
    const required_html = this.props.required ? <span className="required">*</span> : undefined
    const label_pre_1 =
      this.props.type != 'checkbox' && this.props.type != 'radio' && label ? (
        <p className="label pre" style={this.props.preLabelStyle}>
          {label}
          {required_html}
          {tip}
        </p>
      ) : undefined
    const { postLabelStyle } = this.props

    return (
      <div
        data-test={this.props.checkboxContainerId}
        className={classNames}
        style={this.props.style}
        data-has-validator={this.props.validator_type}
      >
        {tipPop}
        <label
          data-test={this.props.checkboxLabelId}
          htmlFor={this.props.id ? this.props.id : `id_${this.props.name}`}
          style={this.props.labelStyle}
        >
          {label_pre_1}
          <p className="input" style={this.props.preInputStyle}>
            {this.getInput()}
          </p>
          {this.props.type == 'checkbox' ? (
            <p style={postLabelStyle} data-test={this.props.testId} className="label post">
              {label} {tip}
            </p>
          ) : undefined}
          {this.props.type == 'radio' ? (
            <p style={postLabelStyle} data-test={this.props.testId} className="label post">
              {this.props.label}
            </p>
          ) : undefined}
          {this.props.incrementor ? <span className="minus">&#8211;</span> : undefined}
          {this.props.incrementor ? <span className="plus">+</span> : undefined}
          {this.props.money ? <span className="sign">{this.props.money}</span> : undefined}
        </label>
        {this.props.validator_message ? (
          <p className="validator" id={this.props.validator_id} data-sr-validate-error={this.props.validator_message}>
            {this.props.validator_message}
          </p>
        ) : undefined}
        {this.props.error ? <p className="error">{this.props.error}</p> : undefined}
        {this.props.hint ? <p className="hint">{this.props.hint}</p> : undefined}
      </div>
    )
  }
}

export class CheckBox extends FormElement {
  constructor(props) {
    super(props)

    this.onClickHandler = this.onClickHandler.bind(this)
  }

  componentDidMount() {
    $(ReactDOM.findDOMNode(this)).val(this.props.value)
  }

  componentDidUpdate() {
    const $el = $(ReactDOM.findDOMNode(this))
    setTimeout(() => {
      $el.toggleClass('checked', this.props.checked)
      $el.val(this.props.value)
    }, 0)
  }

  onClickHandler(e) {
    const target = $(e.target)
    helpers.canuse(target)
    if (_.includes(this.props.classes, 'save')) {
      helpers.onSaveClick(target)
    }
    if (_.includes(this.props.classes, 'cancel')) {
      helpers.onCancelClick(target)
    }
    if (this.props.onClickHandler) {
      this.props.onClickHandler(e)
    }
  }

  getInput() {
    return (
      <input
        type={this.props.type}
        id={this.props.id ? this.props.id : `id_${this.props.name}`}
        name={this.props.name}
        defaultChecked={this.props.checked}
        disabled={this.props.disabled}
        style={this.props.inputCss}
        className={this.props.classes}
        data-attributes={this.props.attributes}
        data-sr-validate={this.props.validator_type}
        data-sr-validate-inject={this.props.validator_inject}
        data-additional={this.props.dataAdditional}
        onClick={this.onClickHandler}
        onFocus={helpers.canuse}
        onMouseDown={helpers.canuse}
      />
    )
  }
}
CheckBox.defaultProps = { type: 'checkbox' }

export class TextArea extends FormElement {
  constructor(props) {
    super(props)

    this.onClickHandler = this.onClickHandler.bind(this)
  }

  onClickHandler(e) {
    const target = $(e.target)
    helpers.canuse(this)
    if (_.includes(this.props.classes, 'save')) {
      helpers.onSaveClick(target)
    }
    if (_.includes(this.props.classes, 'cancel')) {
      helpers.onCancelClick(target)
    }
  }

  getInput() {
    return (
      <textarea
        id={this.props.id ? this.props.id : `id_${this.props.name}`}
        name={this.props.name}
        required={this.props.required}
        className={this.props.classes}
        placeholder={this.props.placeholder}
        disabled={this.props.disabled}
        maxLength={this.props.maxLength}
        autoComplete={this.props.noauto ? 'off' : 'on'}
        data-sr-validate={this.props.validator_type}
        data-sr-validate-inject={this.props.validator_inject}
        onClick={this.onClickHandler}
        onChange={this.onChangeHandler}
        onBlur={this.props.onBlurHandler}
        onInput={this.props.onInputHandler}
        onFocus={helpers.canuse}
        style={this.props.inputCss}
        onMouseDown={helpers.canuse}
        value={this.state.value}
        ref={this.props.inputRef}
      />
    )
  }
}
TextArea.defaultProps = { type: 'textarea', onBlurHandler: () => {}, inputRef: null, onInputHandler: () => {} }

export class Radio extends FormElement {
  constructor(props) {
    super(props)

    this.onClickHandler = this.onClickHandler.bind(this)
  }

  componentDidUpdate() {
    $(ReactDOM.findDOMNode(this)).val(this.props.value)
  }

  onClickHandler(e) {
    if (this.props.onClickHandler) {
      this.props.onClickHandler(e)
    }
  }

  getInput() {
    return (
      <input
        type={this.props.type}
        name={this.props.name}
        id={this.props.id ? this.props.id : `id_${this.props.name}`}
        defaultValue={this.props.value ? this.props.value : 1}
        defaultChecked={!this.props.checkit && this.props.defaultradio}
        disabled={this.props.disabled}
        data-sr-validate={this.props.validator_type}
        data-sr-validate-inject={this.props.validator_inject}
        style={this.props.inputCss}
        onClick={this.onClickHandler}
        onFocus={helpers.canuse}
        onMouseDown={helpers.canuse}
        onKeyUp={helpers.addsave}
      />
    )
  }
}
Radio.defaultProps = { type: 'radio' }

export class Select extends FormElement {
  constructor(props) {
    super(props)

    this.onClickHandler = this.onClickHandler.bind(this)
  }

  onClickHandler(e) {
    if (this.props.onClickHandler) {
      this.props.onClickHandler(e)
    }
  }

  getInput() {
    const { MEDIA_URL, inputCss, options, matchValue, name, disabled, defaultValue, id } = this.props
    const style = {
      ...(MEDIA_URL
        ? {
            backgroundImage: `url(${MEDIA_URL}images/selector.png)`,
            backgroundPositionX: 'right 6px',
            backgroundPositionY: 'top 50%',
            backgroundPosition: 'right 6px top 50%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '10px',
            width: '100%',
          }
        : {}),
      padding: 6,
      ...inputCss,
    }
    const finalOptions = _.map(options, (value, index) => {
      let val = index
      if (matchValue) {
        val = value
      }
      return (
        <option value={val} key={index}>
          {value}
        </option>
      )
    })

    return (
      <select
        name={name}
        style={style}
        disabled={disabled}
        value={this.state.value || defaultValue}
        id={id || `id_${name}`}
        onChange={this.onChangeHandler}
        onFocus={helpers.canuse}
        onClick={this.onClickHandler}
        onMouseDown={helpers.canuse}
        data-sr-validate={this.props.validator_type}
        data-sr-validate-inject={this.props.validator_inject}
      >
        {finalOptions}
      </select>
    )
  }
}
Select.defaultProps = { type: 'select' }

export class Input extends FormElement {
  constructor(props) {
    super(props)

    this.onClickHandler = this.onClickHandler.bind(this)
  }

  componentDidUpdate() {
    $(ReactDOM.findDOMNode(this)).val(this.props.value)
  }

  onClickHandler(e) {
    const target = $(e.target)
    helpers.canuse(target)
  }

  getInput() {
    return (
      <input
        type={this.props.type}
        name={this.props.name}
        min={this.props.min}
        max={this.props.max}
        id={this.props.id ? this.props.id : `id_${this.props.name}`}
        data-dataEncryptedName={this.props.name}
        value={this.state.value}
        className={this.props.classes}
        placeholder={this.props.placeholder}
        required={this.props.required}
        autoComplete={this.props.noauto ? 'off' : ''}
        disabled={this.props.disabled}
        maxLength={this.props.maxLength}
        size={this.props.size}
        width={this.props.width}
        onChange={this.onChangeHandler}
        onBlur={this.props.onBlurHandler}
        data-sr-validate={this.props.validator_type}
        data-sr-validate-inject={this.props.validator_inject}
        onClick={this.onClickHandler}
        onFocus={helpers.canuse}
        onMouseDown={helpers.canuse}
        onKeyUp={helpers.addsave}
        style={this.props.inputCss}
        ref={this.props.inputRef}
      />
    )
  }
}
Input.defaultProps = { type: 'text' }
