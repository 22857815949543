import { defineMessages } from '@sevenrooms/core/locales'

export const reservationWidgetSettingsMessages = defineMessages({
  title: {
    id: 'settings.general.title',
    defaultMessage: 'Reservation Widget Settings',
  },
  error: {
    id: 'settings.general.error',
    defaultMessage: 'Something went wrong...',
  },
  success: {
    id: 'settings.general.success',
    defaultMessage: 'Settings have been saved.',
  },
  tabGeneral: {
    id: 'settings.tab.general',
    defaultMessage: 'General',
  },
  tabCheckoutConfirmation: {
    id: 'settings.tab.tabCheckoutConfirmation',
    defaultMessage: 'Checkout & Confirmation',
  },
  tabSearchAvailability: {
    id: 'settings.tab.tabSearchAvailability',
    defaultMessage: 'Search & Availability',
  },
  characterCount: {
    id: 'settings.characterCount',
    defaultMessage: '{count, plural, one {{count} character} other {{count} characters}}',
  },
  saveAndPublish: {
    id: 'settings.saveAndPublish',
    defaultMessage: 'Save & Publish',
  },
  savingActionLabel: {
    id: 'settings.savingActionLabel',
    defaultMessage: 'Saving...',
  },
  viewLiveWidget: {
    id: 'settings.viewLiveWidget',
    defaultMessage: 'View Live Widget',
  },
  copyWidgetLink: {
    id: 'settings.copyWidgetLink',
    defaultMessage: 'Copy Widget Link',
  },
  linkCopied: {
    id: 'settings.linkCopied',
    defaultMessage: 'Link copied!',
  },
  paymentBannerTitle: {
    id: 'settings.paymentBannerTitle',
    defaultMessage: 'Get Paid Through Your Reservation Widget',
  },
  paymentBannerDescription: {
    id: 'settings.paymentBannerDescription',
    defaultMessage: 'Connect a processor in minutes to enable online payments.',
  },
} as const)
