import { defineMessages } from '@sevenrooms/core/locales'

export const newResWidgetBannerMessages = defineMessages({
  modalLabel: {
    id: 'settings.newResWidget.banner.modal.label',
    defaultMessage: 'New Reservation Widget Setup Modal',
  },
  error: {
    id: 'settings.newResWidget.banner.error',
    defaultMessage: 'Something went wrong...',
  },
  success: {
    id: 'settings.newResWidget.banner.success',
    defaultMessage: 'Settings have been saved.',
  },
  bannerTitle: {
    id: 'settings.newResWidget.banner.title',
    defaultMessage: 'Launch your pre-designed new Reservation Widget',
  },
  bannerDescription: {
    id: 'settings.newResWidget.banner.description',
    defaultMessage: 'Our revamped widget provides a best-in-class guest experience while increasing your conversion rates.',
  },
  bannerLaunchButton: {
    id: 'settings.newResWidget.banner.launch',
    defaultMessage: 'Set Up and Launch',
  },
  bannerPreviewButton: {
    id: 'settings.newResWidget.banner.preview',
    defaultMessage: 'Preview New Widget',
  },
  footerStepNumber: {
    id: 'settings.newResWidget.banner.footer.stepNumber',
    defaultMessage: 'Step {index} of 3',
  },
  uploadHeaderStepTitle: {
    id: 'settings.newResWidget.banner.uploadHeaderStep.title',
    defaultMessage: 'Review or edit your header image',
  },
  uploadHeaderStepBestPracticesTitle: {
    id: 'settings.newResWidget.banner.uploadHeaderStep.bestPractices.title',
    defaultMessage: 'Info and Best Practices',
  },
  uploadHeaderStepBestPracticesPlacementTitle: {
    id: 'settings.newResWidget.banner.uploadHeaderStep.bestPractices.placement.title',
    defaultMessage: 'Placement',
  },
  uploadHeaderStepBestPracticesImageGuidelinesTitle: {
    id: 'settings.newResWidget.banner.uploadHeaderStep.bestPractices.imageGuidelines.title',
    defaultMessage: 'Image Guidelines',
  },
  uploadHeaderStepBestPracticesImageGuidelinesBullet1: {
    id: 'settings.newResWidget.banner.uploadHeaderStep.bestPractices.imageGuidelines.bullet1',
    defaultMessage: 'Use an image of your interior or food to show off the branding, ambience, and vibe',
  },
  uploadHeaderStepBestPracticesImageGuidelinesBullet2: {
    id: 'settings.newResWidget.banner.uploadHeaderStep.bestPractices.imageGuidelines.bullet2',
    defaultMessage: 'Horizontal orientation is best, at least 1400px wide, 600px tall',
  },
  uploadHeaderStepBestPracticesImageGuidelinesBullet3: {
    id: 'settings.newResWidget.banner.uploadHeaderStep.bestPractices.imageGuidelines.bullet3',
    defaultMessage: 'Max 4MB file size',
  },

  reviewVenueDescriptionStepTitle: {
    id: 'settings.newResWidget.banner.reviewVenueDescription.title',
    defaultMessage: 'Review or edit your venue description',
  },
  reviewVenueDescriptionStepBestPracticesTitle: {
    id: 'settings.newResWidget.banner.reviewVenueDescription.bestPractices.title',
    defaultMessage: 'Info and Best Practices',
  },
  reviewVenueDescriptionStepBestPracticesPlacementTitle: {
    id: 'settings.newResWidget.banner.reviewVenueDescription.bestPractices.placement.title',
    defaultMessage: 'Placement',
  },
  reviewVenueDescriptionStepBestPracticesInfoTitle: {
    id: 'settings.newResWidget.banner.reviewVenueDescription.bestPractices.info.title',
    defaultMessage: 'Best Practices',
  },
  reviewVenueDescriptionStepBestPracticesInfoBullet1: {
    id: 'settings.newResWidget.banner.reviewVenueDescription.bestPractices.info.bullet1',
    defaultMessage: 'Give your guests insight about an experience at your venue or what makes you unique',
  },
  reviewVenueDescriptionStepBestPracticesInfoBullet2: {
    id: 'settings.newResWidget.banner.reviewVenueDescription.bestPractices.info.bullet2',
    defaultMessage: '1-2 sentences, approximately 400 characters',
  },

  otherBrandSettingsStepTitle: {
    id: 'settings.newResWidget.banner.otherBrandSettings.title',
    defaultMessage: 'Review or edit other brand settings',
  },
  otherBrandSettingsStepHeaderLogoTitle: {
    id: 'settings.newResWidget.banner.otherBrandSettings.headerLogo.title',
    defaultMessage: 'Header Logo',
  },
  otherBrandSettingsStepPrimaryColorTitle: {
    id: 'settings.newResWidget.banner.otherBrandSettings.primaryColor.title',
    defaultMessage: 'Primary Color',
  },
  otherBrandSettingsStepSecondaryColorTitle: {
    id: 'settings.newResWidget.banner.otherBrandSettings.secondaryColor.title',
    defaultMessage: 'Secondary Color',
  },
  otherBrandSettingsStepBestPracticesTitle: {
    id: 'settings.newResWidget.banner.otherBrandSettingsStep.bestPractices.title',
    defaultMessage: 'Info and Best Practices',
  },
  otherBrandSettingsStepBestPracticesPlacementTitle: {
    id: 'settings.newResWidget.banner.otherBrandSettingsStep.bestPractices.placement.title',
    defaultMessage: 'Placement',
  },
  otherBrandSettingsStepBestPracticesLogoGuidelinesTitle: {
    id: 'settings.newResWidget.banner.otherBrandSettingsStep.bestPractices.logoGuidelines.title',
    defaultMessage: '1: Logo Guidelines',
  },
  otherBrandSettingsStepBestPracticesLogoGuidelinesBullet1: {
    id: 'settings.newResWidget.banner.otherBrandSettingsStep.bestPractices.logoGuidelines.bullet1',
    defaultMessage: 'Recommended: 560 x 200px (20:7 ratio), minimum height 40px',
  },
  otherBrandSettingsStepBestPracticesLogoGuidelinesBullet2: {
    id: 'settings.newResWidget.banner.otherBrandSettingsStep.bestPractices.logoGuidelines.bullet2',
    defaultMessage: 'A horizontal logo is best',
  },
  otherBrandSettingsStepBestPracticesLogoGuidelinesBullet3: {
    id: 'settings.newResWidget.banner.otherBrandSettingsStep.bestPractices.logoGuidelines.bullet3',
    defaultMessage: 'Use a transparent background and upload a .png',
  },
  otherBrandSettingsStepBestPracticesPrimaryColorTitle: {
    id: 'settings.newResWidget.banner.otherBrandSettingsStep.bestPractices.primaryColor.title',
    defaultMessage: '2: Primary Color',
  },
  otherBrandSettingsStepBestPracticesPrimaryColorContent: {
    id: 'settings.newResWidget.banner.otherBrandSettingsStep.bestPractices.primaryColor.content',
    defaultMessage: 'Used for main buttons. Your boldest brand color is best.',
  },
  otherBrandSettingsStepBestPracticesSecondaryColorTitle: {
    id: 'settings.newResWidget.banner.otherBrandSettingsStep.bestPractices.secondaryColor.title',
    defaultMessage: '3: Secondary Color',
  },
  otherBrandSettingsStepBestPracticesSecondaryColorContent: {
    id: 'settings.newResWidget.banner.otherBrandSettingsStep.bestPractices.secondaryColor.content',
    defaultMessage: 'Used as a background color, primarily behind your logo.',
  },
  otherBrandSettingsStepSaveAndContinue: {
    id: 'settings.newResWidget.banner.saveAndContinue',
    defaultMessage: 'Save & Continue',
  },
  otherBrandSettingsStepSaving: {
    id: 'settings.newResWidget.banner.saving',
    defaultMessage: 'Saving...',
  },
  launchCompleteModalTitle: {
    id: 'settings.newResWidget.banner.launchComplete.modal.title',
    defaultMessage: 'Congratulations! Your new widget is live!',
  },
  launchCompleteModalTitleSetupPayment: {
    id: 'settings.newResWidget.banner.launchComplete.modal.title.setup.payment',
    defaultMessage: 'Your new Widget is live, now make money with it!',
  },
  defaultModalTitle: {
    id: 'settings.newResWidget.banner.modal.title',
    defaultMessage: 'New Reservation Widget',
  },
  launchWidgetContentStepLaunchButton: {
    id: 'settings.newResWidget.banner.launchWidget.button.launch',
    defaultMessage: 'Launch',
  },
  launchWidgetContentStepLaunchLoadingButton: {
    id: 'settings.newResWidget.banner.launchWidget.button.launch.loading',
    defaultMessage: 'Launching...',
  },
  launchWidgetContentStepPreviewButton: {
    id: 'settings.newResWidget.banner.launchWidget.button.preview',
    defaultMessage: 'Preview',
  },
  introTitle: {
    id: 'settings.newResWidget.banner.intro.title',
    defaultMessage: 'Launch your new Reservation Widget!',
  },
  introDescription: {
    id: 'settings.newResWidget.banner.intro.description',
    defaultMessage: 'Our revamped design provides a best-in-class guest experience that increases conversions.',
  },
  introRemindLaterBtn: {
    id: 'settings.newResWidget.banner.intro.button.remindLater',
    defaultMessage: 'Remind Me Later',
  },
  introSetupNowBtn: {
    id: 'settings.newResWidget.banner.intro.button.setupNow',
    defaultMessage: 'Set Up Now',
  },
  introImprovedSectionTitle: {
    id: 'settings.newResWidget.banner.intro.improvedSection.title',
    defaultMessage: 'Improved guest experience',
  },
  introImprovedSectionDescription: {
    id: 'settings.newResWidget.banner.intro.improvedSection.description',
    defaultMessage: '36% improvement in conversion and 17% faster booking time',
  },
  introOptimizedSectionTitle: {
    id: 'settings.newResWidget.banner.intro.optimizedSection.title',
    defaultMessage: 'Optimized for mobile',
  },
  introOptimizedSectionDescription: {
    id: 'settings.newResWidget.banner.intro.optimizedSection.description',
    defaultMessage: 'Nearly 80% of bookings happen on phones',
  },
  introDesignSectionTitle: {
    id: 'settings.newResWidget.banner.intro.designSection.title',
    defaultMessage: 'Pre-designed and ready to go!',
  },
  introDesignSectionDescription: {
    id: 'settings.newResWidget.banner.intro.designSection.description',
    defaultMessage: 'Instantly stand out with your customized design',
  },
} as const)
