import { Component } from 'react'
import { connect } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import Theme from 'mgr/layout/Theme'
import { tryGetWidgetSettings, tryPostWidgetSettings, updateField, updateTheme, restoreDefault } from '../actions/services'
import { WidgetSettingsForm } from '../components/widgetsettings'
import { NewResWidgetBanner } from '../components/newreswidgetbanner/NewResWidgetBanner'
import { Banner, Root, Box } from '@sevenrooms/core/ui-kit/layout'
import { VenuePrivilegeLevelEnum } from '@sevenrooms/core/domain'
import { Icon } from '@sevenrooms/core/ui-kit/icons'

class App extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.actions.tryGetWidgetSettings(this.props.venueId)
  }

  render() {
    const { widgetSettings } = this.props
    const showBanner =
      [VenuePrivilegeLevelEnum.MANAGER, VenuePrivilegeLevelEnum.SUPERUSER].includes(window.globalInit.userDomain.venue_privilege_level) &&
      !window.globalInit.venueSettings.payments_enabled
    const bannerContent = (
      <Banner
        canDismiss={false}
        type="attention"
        title="Get Paid Through Your Reservation Widget"
        description="Connect a processor in minutes to enable online payments."
        icon={<Icon name="VMSWeb-creditcard" size="2x" />}
        action="Connect"
        onAction={() => window.open(`/manager2/${window.globalInit.venueUrlKey}/settings/payment-integration/view`, '_blank')}
      />
    )

    return (
      <ThemeProvider theme={Theme}>
        <div>
          <div id="content-header">
            <h2>Reservation Widget Settings</h2>
          </div>
          <Root theme="vx">
            {showBanner && bannerContent}
            {widgetSettings.newResWidgetEnabled && (
              <Box pt={showBanner ? 'lm' : '3xl'}>
                <NewResWidgetBanner />
              </Box>
            )}
          </Root>
          <WidgetSettingsForm
            MEDIA_URL={this.props.MEDIA_URL}
            widgetSettings={this.props.widgetSettings}
            appState={this.props.appState}
            actions={this.props.actions}
          />
        </div>
      </ThemeProvider>
    )
  }
}

const mapStateToProps = state => ({
  widgetSettings: state.widgetSettings,
  appState: state.appState,
})

const mapDispatchToProps = dispatch => ({
  actions: {
    updateField: (fieldName, val, error) => {
      dispatch(updateField(fieldName, val, error))
    },
    updateTheme: () => {
      dispatch(updateTheme())
    },
    tryGetWidgetSettings: venueId => {
      dispatch(tryGetWidgetSettings(venueId))
    },
    tryPostWidgetSettings: widgetSettings => {
      dispatch(tryPostWidgetSettings(widgetSettings))
    },
    restoreDefault: () => {
      dispatch(restoreDefault())
    },
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
