import _ from 'lodash'
import { Component } from 'react'
import Dropzone from 'react-dropzone'
import { connect } from 'react-redux'
import { uploadPhoto, cropOpen, cropDelete } from '../actions/services'
import * as styles from '../assets/styles.js'

class ImageUpload extends Component {
  constructor(props) {
    super(props)
    this.onDrop = this.onDrop.bind(this)
    this.interfaceId = `sr-drop-interface-${this.props.imageType}`
    this.imgId = `sr-upload-img-${this.props.imageType}`

    this.uploadPhoto = this.props.uploadPhoto.bind(this, this.props.imageType)
    this.cropDelete = this.props.cropDelete.bind(this, this.props.imageType)
    this.cropOpen = this.props.cropOpen.bind(this, this.props.imageType)
    this.onLoad = this.onLoad.bind(this)
    this.state = { imageLoaded: false }
  }

  onLoad() {
    this.setState({ imageLoaded: true })
  }

  onDrop(accepted) {
    this.uploadPhoto(accepted[0])
  }

  render() {
    const { cropData, url, imageType, style } = this.props

    const actionEdit = _.assign({}, styles.action, {
      backgroundImage: `url(${MEDIA_URL}images/eventmanager/edit.png)`,
    })

    const actionDelete = _.assign({}, styles.action, {
      backgroundImage: `url(${MEDIA_URL}images/eventmanager/delete.png)`,
    })

    const largeImageStyle = _.assign({}, styles.hasPhoto, styles.bigPhoto, style)
    const emptyPhotoStyle = _.assign({}, styles.emptyPhoto, styles.bigPhoto, style)

    let crop = {}
    const imgElement = document.getElementById(this.imgId)
    let interfaceWidth = 490
    if (!_.isEmpty(cropData) && this.state.imageLoaded) {
      const cropPercentage = cropData.height / 100
      const cropHeight = imgElement.naturalHeight * cropPercentage
      const factor = 210 / cropHeight
      const imgHeight = factor * imgElement.naturalHeight
      const imgWidth = imgElement.naturalWidth * factor
      const topOffset = imgHeight * (cropData.y / 100)
      const leftOffset = imgWidth * (cropData.x / 100)
      const height = `${imgHeight}px`
      const top = `-${topOffset}px`
      const left = `-${leftOffset}px`
      interfaceWidth = imgWidth * (cropData.width / 100)
      crop = { height, top, left }
    }

    const cropStyle = _.assign({}, styles.photoStyle, crop, { width: 'auto' })

    const content = url ? (
      <div id={this.interfaceId} style={_.assign({}, largeImageStyle, { width: `${interfaceWidth}px` })}>
        <img id={this.imgId} src={url} onLoad={this.onLoad} style={cropStyle} />
        <div style={styles.photoActions}>
          <p onClick={this.cropOpen} style={$.extend({}, actionEdit, styles.actionAdjust)} />
          <p onClick={this.cropDelete} style={$.extend({}, actionDelete, styles.actionAdjust)} />
        </div>
      </div>
    ) : (
      <div id={this.interfaceId}>
        <Dropzone style={emptyPhotoStyle} onDrop={this.onDrop} multiple={false} />
      </div>
    )

    return (
      <div style={styles.bgImageUpload}>
        <p className="group-label">{`${imageType[0].toUpperCase() + imageType.slice(1)} Image`}</p>
        {content}
      </div>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  uploadPhoto: (imageType, file) => {
    dispatch(uploadPhoto(imageType, file))
  },
  cropDelete: imageType => {
    dispatch(cropDelete(imageType))
  },
  cropOpen: imageType => {
    dispatch(cropOpen(imageType))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ImageUpload)
