import React, { Component } from 'react'
import { ChromePicker } from 'react-color'
import { getContrastingColor } from './Color'

class ColorPicker extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }

    this.onClick = this.onClick.bind(this)
    this.onSelectColor = this.onSelectColor.bind(this)
    this.onClose = this.onClose.bind(this)
    this.hexToRgb = this.hexToRgb.bind(this)
  }

  onClick() {
    this.setState({ open: !this.state.open })
  }

  onSelectColor(color) {
    this.props.onChangeColor(color)
  }

  onClose() {
    this.setState({ open: false })
  }

  hexToRgb(hex, opacity) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
          a: opacity,
        }
      : null
  }

  getContrastYIQ(hex) {
    return getContrastingColor(hex)
  }

  render() {
    const buttonStyle = {
      backgroundColor: this.props.color.hex,
      width: '150px',
      textAlign: 'center',
      paddingTop: '7px',
      paddingBottom: '7px',
      color: this.getContrastYIQ(this.props.color.hex),
      cursor: 'pointer',
      fontSize: '14px',
      textTransform: 'uppercase',
      marginBottom: '10px',
    }
    const openStyle = {
      display: 'block',
      position: 'absolute',
      left: '160px',
      top: '0px',
      zIndex: '9999',
    }
    const containerStyle = {
      position: 'relative',
    }
    const coverStyle = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }

    return (
      <div style={containerStyle}>
        <div style={buttonStyle} onClick={this.onClick} data-test={this.props.dataTest}>
          {this.props.color.hex}
          {!this.props.disableAlpha ? ` (${parseFloat(this.props.color.opacity).toFixed(2)})` : ''}
        </div>
        {this.state.open ? (
          <div style={openStyle}>
            <div style={coverStyle} onClick={this.onClose} />
            <ChromePicker
              color={this.hexToRgb(this.props.color.hex, this.props.color.opacity)}
              disableAlpha={this.props.disableAlpha}
              onChangeComplete={this.onSelectColor}
            />
          </div>
        ) : null}
      </div>
    )
  }
}

ColorPicker.propTypes = {
  onChangeColor: React.PropTypes.func.isRequired,
}

ColorPicker.defaultProps = {
  color: { hex: '#FFFFFF', opacity: 1.0 },
  disableAlpha: true,
  dataTest: '',
}

export default ColorPicker
