import { useLocales } from '@sevenrooms/core/locales'
import { IconButton } from '@sevenrooms/core/ui-kit/form'
import { Flex, ModalFooter, ModalActions } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { newResWidgetBannerMessages } from '../NewResWidgetBanner.messages'
import type { ReactElement } from 'react'

interface NewResWidgetModalFooterProp {
  disabled?: boolean
  onPrevious?: () => void
  stepIndex?: number
  actions: ReactElement
}
export function NewResWidgetModalFooter({ actions, onPrevious, disabled, stepIndex }: NewResWidgetModalFooterProp) {
  const { formatMessage } = useLocales()

  return (
    <ModalFooter data-test="sr-modal-footer" borderTopColor="borders" borderWidth="s" p="s sm">
      <Flex justifyContent="space-between" alignItems="center">
        <Flex justifyContent="space-between" alignItems="center" width="50%">
          <IconButton
            size="m"
            data-test="back-button"
            borderType="none-round"
            icon="VMSWeb-chevron-left"
            iconColor="primaryIcons"
            disabled={disabled}
            onClick={onPrevious}
            aria-label="back"
          />
          {stepIndex && (
            <Text textStyle="body1" data-test="sr-current-step">
              {formatMessage(newResWidgetBannerMessages.footerStepNumber, { index: stepIndex })}
            </Text>
          )}
        </Flex>
        <ModalActions>{actions}</ModalActions>
      </Flex>
    </ModalFooter>
  )
}
