import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore, compose } from 'redux'
import thunk from 'redux-thunk'
import App from './containers/app'
import rootReducer from './reducers/settingsreducer'
import { languageApi, reservationWidgetSettingsApiV2 } from '@sevenrooms/core/api'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(thunk),
    applyMiddleware(reservationWidgetSettingsApiV2.middleware),
    applyMiddleware(languageApi.middleware)
  )
)

// get some initialization data
const { venueId, MEDIA_URL } = window.widgetSettingsInit

render(
  <Provider store={store}>
    <App venueId={venueId} MEDIA_URL={MEDIA_URL} />
  </Provider>,
  document.getElementById('widget-settings')
)
