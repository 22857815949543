import { useState } from 'react'
import { ReservationWidgetSettingsServiceV2 } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalActions, ModalFooter, ModalHeader, ModalTitle, notify } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core/hooks/useVenueContext'
import { generalMessages } from './General.locales'

interface RevertConfirmationModalProps {
  onClose: () => void
}
export function RevertConfirmationModal({ onClose }: RevertConfirmationModalProps) {
  const { formatMessage } = useLocales()
  const { venueId, venueKey } = useVenueContext()

  const [isRevertingToLegacy, setIsRevertingToLegacy] = useState(false)

  const revertToLegacy = async () => {
    setIsRevertingToLegacy(true)
    try {
      await ReservationWidgetSettingsServiceV2.setReservationWidgetRedirect(venueId, false)
      const oldResWidgetSettingsUrl = `/manager/${venueKey}/settings/widgets/dining`
      window.location.assign(oldResWidgetSettingsUrl)
    } catch (e) {
      setIsRevertingToLegacy(false)
      notify({ content: formatMessage(generalMessages.legacyReservationWidgetRevertError), type: 'error' })
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  return (
    <Modal ariaLabel="Revert Confirmation Modal" data-test="sr-revert-confirmation-modal">
      <ModalHeader onClose={onClose}>
        <ModalTitle title={formatMessage(generalMessages.legacyReservationWidgetRevertConfirmationHeader)} />
      </ModalHeader>
      <ModalFooter>
        <ModalActions>
          <Button
            variant="secondary"
            onClick={() => {
              revertToLegacy()
            }}
            disabled={isRevertingToLegacy}
            data-test="sr-revert-confirm"
          >
            {formatMessage(generalMessages.legacyReservationWidgetRevertConfirmationAction)}
          </Button>
          <Button variant="primary" onClick={onClose} disabled={isRevertingToLegacy} data-test="sr-revert-cancel">
            {formatMessage(generalMessages.legacyReservationWidgetRevertConfirmationRejection)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
