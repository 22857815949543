import { type Field, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { reservationWidgetSettingsMessages } from './ReservationWidgetSettings.locales'

export function FormCharacterCounter({ field }: { field: Field<string> }) {
  const fieldValue = useWatch(field)

  return <CharacterCounter value={fieldValue} />
}

export function CharacterCounter({ value }: { value?: string }) {
  const { formatMessage } = useLocales()

  return (
    <SecondaryText textStyle="body1">
      {formatMessage(reservationWidgetSettingsMessages.characterCount, { count: value?.length || 0 })}
    </SecondaryText>
  )
}
