import _ from 'lodash'
import { Component } from 'react'
import Dropzone from 'react-dropzone'
import { connect } from 'react-redux'
import { uploadPhoto, cropOpen, cropDelete } from '../actions/services'
import * as styles from '../assets/styles.js'

class ImageUpload extends Component {
  constructor(props) {
    super(props)
    this.onDrop = this.onDrop.bind(this)
    this.interfaceId = `sr-drop-interface-${this.props.imageType}`
    this.imgId = `sr-upload-img-${this.props.imageType}`

    this.uploadPhoto = this.props.uploadPhoto.bind(this, this.props.imageType)
    this.cropDelete = this.props.cropDelete.bind(this, this.props.imageType)
    this.cropOpen = this.props.cropOpen.bind(this, this.props.imageType)
  }

  onDrop(accepted) {
    this.uploadPhoto(accepted[0])
  }

  render() {
    const { cropData, url, imageType, style } = this.props

    const actionEdit = _.assign({}, styles.action, {
      backgroundImage: `url(${MEDIA_URL}images/eventmanager/edit.png)`,
    })

    const actionDelete = _.assign({}, styles.action, {
      backgroundImage: `url(${MEDIA_URL}images/eventmanager/delete.png)`,
    })

    const largeImageStyle = _.assign({}, styles.hasPhoto, styles.bigPhoto, style)
    const emptyPhotoStyle = _.assign({}, styles.emptyPhoto, styles.bigPhoto, style)

    let crop = {}
    const interFaceElement = document.getElementById(this.interfaceId)
    if (!_.isEmpty(cropData)) {
      const interFaceWidth = 490
      const interFaceHeight = 210
      const imgWidth = (100 / cropData.width) * interFaceWidth
      const imgHeight = (100 / cropData.height) * interFaceHeight
      const topOffset = imgHeight * (cropData.y / 100)
      const leftOffset = imgWidth * (cropData.x / 100)
      const width = `${imgWidth}px`
      const top = `-${topOffset}px`
      const left = `-${leftOffset}px`
      crop = { width, top, left }
    }

    const cropStyle = _.assign({}, styles.photoStyle, crop)

    const content = url ? (
      <div id={this.interfaceId} style={largeImageStyle}>
        <img id={this.imgId} src={url} style={cropStyle} />
        <div style={styles.photoActions}>
          <p onClick={this.cropOpen} style={$.extend({}, actionEdit, styles.actionAdjust)} />
          <p onClick={this.cropDelete} style={$.extend({}, actionDelete, styles.actionAdjust)} />
        </div>
      </div>
    ) : (
      <div id={this.interfaceId}>
        <Dropzone style={emptyPhotoStyle} onDrop={this.onDrop} multiple={false} />
      </div>
    )

    return (
      <div style={styles.bgImageUpload}>
        <p className="group-label">{`${imageType[0].toUpperCase() + imageType.slice(1)} Image`}</p>
        {content}
      </div>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  uploadPhoto: (imageType, file) => {
    dispatch(uploadPhoto(imageType, file))
  },
  cropDelete: imageType => {
    dispatch(cropDelete(imageType))
  },
  cropOpen: imageType => {
    dispatch(cropOpen(imageType))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ImageUpload)
