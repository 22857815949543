import { type FormEvent, type PropsWithChildren, type ReactElement, useCallback, useMemo } from 'react'
import type { LanguageCode } from '@sevenrooms/core/api'
import type { ReservationWidgetSettingsLanguageString, VenueLanguage } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Select, type SelectOption } from '@sevenrooms/core/ui-kit/core'
import { TextArea } from '@sevenrooms/core/ui-kit/form'
import { Box, Spreadsheet, type DataTableColumn, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { otherLanguagesTableMessages } from './OtherLanguagesTable.locales'

export interface OtherLanguagesTableProps {
  onChange: (value: string, language: LanguageCode, stringToChange: ReservationWidgetSettingsLanguageString) => void
  selectedLanguage: LanguageCode
  onSelectedLanguageChange: (value: LanguageCode) => void
  languageOptions: SelectOption<LanguageCode>[]
  defaultLanguage: VenueLanguage
  data: OtherLanguagesTableColumn[]
}

export interface OtherLanguagesTableColumn {
  id: ReservationWidgetSettingsLanguageString
  description: ReactElement
  defaultLanguage: string
  selectedLanguage?: string
}

const OtherLanguagesTableColumnWidth = 250

export function DescriptionColumn({ primary, secondary }: { primary: string; secondary?: string }) {
  return (
    <VStack spacing="xs" mt="xs">
      <Text textStyle="body1Bold">{primary}</Text>
      {secondary && <Text>{secondary}</Text>}
    </VStack>
  )
}

function OtherLanguagesBaseColumn({
  children,
  'data-test': dataTest,
  removeRightPadding,
}: PropsWithChildren<{ 'data-test'?: string; removeRightPadding?: boolean }>) {
  return (
    <Box p={removeRightPadding ? 'm none m m' : 'm'} width="100%" data-test={dataTest}>
      {children}
    </Box>
  )
}

export function OtherLanguagesTable({
  data,
  selectedLanguage,
  languageOptions,
  defaultLanguage,
  onSelectedLanguageChange,
  onChange,
}: OtherLanguagesTableProps) {
  const { formatMessage } = useLocales()

  const onLanguageChange = useCallback(
    (language: LanguageCode) => {
      onSelectedLanguageChange(language)
    },
    [onSelectedLanguageChange]
  )

  const columns = useMemo<DataTableColumn<OtherLanguagesTableColumn>[]>(() => {
    const columns: DataTableColumn<OtherLanguagesTableColumn>[] = [
      {
        header: '',
        key: 'description',
        render: (value: OtherLanguagesTableColumn) => (
          <OtherLanguagesBaseColumn removeRightPadding data-test={`sr-${value.id}-description-column`}>
            {value.description}
          </OtherLanguagesBaseColumn>
        ),
        textDisplay: 'flex',
        headerAlign: 'start',
        alignItems: 'start',
        minWidth: OtherLanguagesTableColumnWidth,
        width: OtherLanguagesTableColumnWidth,
        backgroundColor: 'secondaryBackground',
        removePadding: true,
      },
      {
        header: () => <OtherLanguagesBaseColumn removeRightPadding>{defaultLanguage.name}</OtherLanguagesBaseColumn>,
        key: 'defaultLanguage',
        render: (value: OtherLanguagesTableColumn) => (
          <OtherLanguagesBaseColumn removeRightPadding={!!selectedLanguage} data-test={`sr-${value.id}-default-language-column`}>
            <TextArea
              data-test={`sr-${value.id}-default-language-text-area`}
              placeholder={formatMessage(otherLanguagesTableMessages.otherLanguagesTableColumnPlaceholder)}
              aria-label={formatMessage(otherLanguagesTableMessages.otherLanguagesTableColumnPlaceholder)}
              fullWidth
              resize="none"
              value={value.defaultLanguage}
              onChange={(e: FormEvent<HTMLTextAreaElement>) => {
                onChange(e.currentTarget.value, defaultLanguage.value, value.id)
              }}
            />
          </OtherLanguagesBaseColumn>
        ),
        textDisplay: 'flex',
        headerAlign: 'start',
        width: OtherLanguagesTableColumnWidth,
        minWidth: OtherLanguagesTableColumnWidth,
        removePadding: true,
      },
    ]
    if (selectedLanguage) {
      columns.push({
        header: () => (
          <OtherLanguagesBaseColumn>
            <Select options={languageOptions} value={selectedLanguage} onChange={onLanguageChange} searchable={false} />
          </OtherLanguagesBaseColumn>
        ),
        key: 'selectedLanguage',
        render: (value: OtherLanguagesTableColumn) => (
          <OtherLanguagesBaseColumn data-test={`sr-${value.id}-selected-language-column`}>
            <TextArea
              data-test={`sr-${value.id}-selected-language-text-area`}
              placeholder={formatMessage(otherLanguagesTableMessages.otherLanguagesTableColumnPlaceholder)}
              aria-label={formatMessage(otherLanguagesTableMessages.otherLanguagesTableColumnPlaceholder)}
              fullWidth
              resize="none"
              value={value.selectedLanguage || ''}
              onChange={(e: FormEvent<HTMLTextAreaElement>) => {
                onChange(e.currentTarget.value, selectedLanguage, value.id)
              }}
            />
          </OtherLanguagesBaseColumn>
        ),
        textDisplay: 'flex',
        headerAlign: 'start',
        width: OtherLanguagesTableColumnWidth,
        minWidth: OtherLanguagesTableColumnWidth,
        removePadding: true,
      })
    }
    return columns
  }, [selectedLanguage, defaultLanguage.name, defaultLanguage.value, formatMessage, onChange, languageOptions, onLanguageChange])

  return <Spreadsheet data={data} columns={columns} noPagination />
}
