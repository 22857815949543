import type { ImageObject } from '@sevenrooms/core/domain'
import type { Field } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { ImageUploader, Button } from '@sevenrooms/core/ui-kit/form'
import { HStack, Image, VStack, ModalBody } from '@sevenrooms/core/ui-kit/layout'
import { List, ListItem, Text } from '@sevenrooms/core/ui-kit/typography'
import { newResWidgetBannerMessages } from '../NewResWidgetBanner.messages'
import { NewResWidgetModalFooter } from '../NewResWidgetModalFooter'

const { mediaUrl } = window.globalInit

interface UploadHeaderStepProp {
  stepIndex: number
  field: Field<ImageObject | null>
  onComplete: () => void
  onPrevious: () => void
}

export function UploadHeaderStep({ field, stepIndex, onComplete, onPrevious }: UploadHeaderStepProp) {
  const { formatMessage } = useLocales()

  return (
    <>
      <ModalBody pl="3xl" pr="3xl">
        <HStack justifyContent="center" spacing="3xl">
          <VStack spacing="m" width="475px" pt="l" pb="l">
            <Text textStyle="h2">{formatMessage(newResWidgetBannerMessages.uploadHeaderStepTitle)}</Text>
            <ImageUploader data-test="sr-logo-uploader" field={field} maxSize={4000000} disableEdit variant="small" fullWidth />
          </VStack>
          <VStack p="l" spacing="lm" backgroundColor="secondaryBackground" borderRadius="m" m="l none" width="300px" height="420px">
            <Text textStyle="body1Bold">{formatMessage(newResWidgetBannerMessages.uploadHeaderStepBestPracticesTitle)}</Text>
            <VStack spacing="sm">
              <Text textStyle="body2Medium" textTransform="uppercase" color="attentionNew" letterSpacing="m">
                {formatMessage(newResWidgetBannerMessages.uploadHeaderStepBestPracticesPlacementTitle)}
              </Text>
              <Image
                width="237px"
                height="123px"
                src={`${mediaUrl}images/manager/settings/reswidget/placement-header-image.png`}
                alt="placememnt header image"
              />
            </VStack>
            <VStack spacing="sm">
              <Text textStyle="body2Medium" textTransform="uppercase" color="attentionNew" letterSpacing="m">
                {formatMessage(newResWidgetBannerMessages.uploadHeaderStepBestPracticesImageGuidelinesTitle)}
              </Text>
              <List spacing="xs">
                <ListItem>
                  <Text textStyle="body1">
                    {formatMessage(newResWidgetBannerMessages.uploadHeaderStepBestPracticesImageGuidelinesBullet1)}
                  </Text>
                </ListItem>
                <ListItem>
                  <Text textStyle="body1">
                    {formatMessage(newResWidgetBannerMessages.uploadHeaderStepBestPracticesImageGuidelinesBullet2)}
                  </Text>
                </ListItem>
                <ListItem>
                  <Text textStyle="body1">
                    {formatMessage(newResWidgetBannerMessages.uploadHeaderStepBestPracticesImageGuidelinesBullet3)}
                  </Text>
                </ListItem>
              </List>
            </VStack>
          </VStack>
        </HStack>
      </ModalBody>
      <NewResWidgetModalFooter
        stepIndex={stepIndex}
        onPrevious={onPrevious}
        actions={
          <Button variant="primary" onClick={onComplete} data-test="sr-continue-button">
            {formatMessage(commonMessages.continue)}
          </Button>
        }
      />
    </>
  )
}
