// /* @flow */
import PropTypes from 'prop-types'
import * as React from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import RadioSymbol from '../RadioSymbol'

/* type Props = {
  onClick: boolean | (() => mixed),
  radioId: string,
  label: string,
  tip: string,
  name: string,
  isSelected: boolean,
  children?: React.Node,
  customRadioWrapper: Array<string | (() => mixed)>,
  customRadioIcon: Array<string | (() => mixed)>,
  customRadioCheck: Array<string | (() => mixed)>,
  customRadioLabel: Array<string | (() => mixed)>,
} */
const informationStyle = {
  cursor: 'pointer',
  lineHeight: '20px',
  background: '#ccc',
  borderRadius: 12,
  color: '#fff',
  display: 'inline-block',
  fontFamily: 'Times',
  fontSize: 10,
  fontWeight: 'bold',
  marginLeft: 5,
  textAlign: 'center',
  width: 12,
}

function RadioLine(props) {
  let tip
  let tipPop
  if (props.tip) {
    tip = (
      <span data-for={`${props.name}tip`} data-tip={props.tip} style={informationStyle}>
        i
      </span>
    )
    tipPop = <ReactTooltip id={`${props.name}tip`} effect="solid" />
  }
  return (
    <RadioWrapper customRadioWrapper={props.customRadioWrapper}>
      {tipPop}
      <RadioSymbol
        radioId={props.radioId}
        onClick={props.onClick}
        isSelected={props.isSelected}
        customRadioIcon={props.customRadioIcon}
        customRadioCheck={props.customRadioCheck}
      />
      <RadioLabel htmlFor={props.radioId} customRadioLabel={props.customRadioLabel}>
        {props.label}
      </RadioLabel>
      {tip}
      {props.children}
    </RadioWrapper>
  )
}

RadioLine.defaultProps = {
  isSelected: false,
  customRadioWrapper: [],
  customRadioIcon: [],
  customRadioCheck: [],
  customRadioLabel: [],
}

const RadioWrapper = styled.div`
  padding: 0 0 0 24px;
  min-height: 30px;
  position: relative;
  box-sizing: border-box;
  ${props => props.customRadioWrapper};
`

const RadioLabel = styled.label`
  font-size: 15px;
  line-height: 30px;
  display: inline-block;
  cursor: pointer;
  font-family: inherit;
  box-sizing: inherit;
  ${props => props.customRadioLabel};
`

RadioLine.propTypes = {
  tip: PropTypes.string,
  name: PropTypes.string,
  isSelected: PropTypes.bool,
  customRadioWrapper: PropTypes.array,
  customRadioIcon: PropTypes.array,
  customRadioCheck: PropTypes.array,
  customRadioLabel: PropTypes.array,
  radioId: PropTypes.any,
  onClick: PropTypes.any,
  label: PropTypes.string,
  children: PropTypes.any,
}

export default RadioLine
