import mergeRefs from 'react-merge-refs'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Link, useNavigation } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { useOverflowed, useScrollController, useThrottledResizeObserver } from '@sevenrooms/core/ui-kit/hooks'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import {
  Box,
  DividerLine,
  Modal,
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
  type ModalHeaderProps,
  ModalTitle,
} from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { otherLanguagesModalMessages } from './OtherLanguagesModal.locales'
import type { PropsWithChildren } from 'react'

export interface OtherLanguagesModalProps {
  onClose?: NonNullable<ModalHeaderProps['onClose']>
  onSave: () => void
}

export function OtherLanguagesModal({ children, onSave, onClose }: PropsWithChildren<OtherLanguagesModalProps>) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venue } = useVenueContext()
  const { ref: resizeRef, height: wrapperHeight } = useThrottledResizeObserver(50, 'border-box')
  const { ref: scrollRef, canScrollDown, canScrollUp } = useScrollController(undefined, 'smooth')
  const [isOverflowed, overflowRef] = useOverflowed(wrapperHeight)

  return (
    <Modal ariaLabel="Modal" data-test="sr-other-languages-modal">
      <ModalHeader onClose={onClose} position="relative" boxShadow={isOverflowed && canScrollUp ? 'tertiary' : 'none'}>
        <ModalTitle
          title={formatMessage(otherLanguagesModalMessages.otherLanguagesModalHeaderLabel)}
          subTitle={formatMessage(otherLanguagesModalMessages.otherLanguagesModalHeaderDescription, {
            link: (chunks: string[]) => (
              <Link to={nav.href(routes.manager2.settings.widgets.reservation, { params: { venueKey: venue.urlKey } })} target="_blank">
                {chunks}
                &nbsp;
                <Icon name="VMSWeb-open-in-new" size="sm" />
              </Link>
            ),
          })}
          data-test="sr-other-languages-modal-title"
        />
      </ModalHeader>
      {isOverflowed && <DividerLine margin="none" />}
      <ModalBody data-test="sr-other-languages-modal-body" ref={mergeRefs([overflowRef, resizeRef, scrollRef])}>
        <Box m={isOverflowed ? 'm none' : 'none'}>{children}</Box>
      </ModalBody>
      {isOverflowed && <DividerLine margin="none" />}
      <ModalFooter p="m lm" position="relative" boxShadow={isOverflowed && canScrollDown ? 'tertiaryUp' : 'none'}>
        <ModalActions>
          <Button variant="secondary" onClick={onClose} data-test="sr-other-languages-modal-cancel">
            {formatMessage(commonMessages.cancel)}
          </Button>
          <Button variant="primary" onClick={onSave} data-test="sr-other-languages-modal-save">
            {formatMessage(commonMessages.save)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
