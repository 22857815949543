import _ from 'lodash'
import React, { Component } from 'react'
import ReactCrop from 'react-image-crop'
import { connect } from 'react-redux'
import Button from '../../../../common/Button'
import { cropImage, closeCrop } from '../actions/services'
import * as styles from '../assets/styles'

class Cropper extends Component {
  constructor(props) {
    super(props)

    this.onImageLoadCrop = this.onImageLoadCrop.bind(this)
    this.cropImage = this.props.cropImage.bind(this, this.props.imageType)
    this.closeCrop = this.props.closeCrop.bind(this, this.props.imageType)
  }

  onImageLoadCrop(percentCoords, image, pixelCoords) {
    const cropImage = percentCoords.height ? percentCoords : { ...percentCoords, height: 40 }
    this.cropImage(cropImage)
  }

  render() {
    const noteStyle = _.assign({ margin: '5px 10px 10px' }, styles.eg)
    const message =
      'For your background image, we recommend uploading a rectangular ' +
      'image that is no smaller than 1500 pixels wide and 750 pixels tall. ' +
      'Note your image must be below 4mb.'
    const cropObj = this.props.cropData
      ? this.props.cropData
      : {
          x: 25,
          y: 25,
          width: 50,
        }

    return (
      <div id="crop-interface">
        <div style={styles.shadow} />
        <div style={styles.cropWrap}>
          <div id="content-header" style={styles.headerStyle}>
            <h2 style={styles.headerTextStyle}>{`${this.props.imageType[0].toUpperCase() + this.props.imageType.slice(1)} Image`}</h2>
          </div>
          <div onClick={this.closeCrop} style={styles.closer}>
            ×
          </div>
          <ReactCrop
            src={this.props.url}
            crop={cropObj}
            onComplete={this.cropImage}
            onImageLoaded={this.onImageLoadCrop}
            minHeight={10}
            minWidth={10}
          />
          <div>
            <p style={noteStyle}>{message}</p>
          </div>
          <div style={{ clear: 'both', paddingTop: 10 }}>
            <Button label="Save" onClick={this.closeCrop} style={{ width: 320, margin: '5px 10px' }} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  cropImage: (imageType, percentCoords) => {
    dispatch(cropImage(imageType, percentCoords))
  },
  closeCrop: imageType => {
    dispatch(closeCrop(imageType))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Cropper)
