import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useGetVenueLanguagesQuery, type LanguageListData, type LanguageCode } from '@sevenrooms/core/api'
import type { ReservationWidgetSettingsV2, VenueLanguage } from '@sevenrooms/core/domain'
import { useWatch, type Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { TextArea, ColorPicker, ImageUploader, Label, Button, Checkbox, FormInput } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { HStack, VStack, BaseSection, Box, Window } from '@sevenrooms/core/ui-kit/layout'
import { SecondaryText, Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core/hooks/useVenueContext'
import { routes } from '@sevenrooms/routes'
import { OtherLanguagesModal } from '../components'
import { FormCharacterCounter } from '../FormCharacterCounter'
import { generalMessages } from './General.locales'
import { GeneralLanguagesTable } from './GeneralLanguagesTable'
import { RevertConfirmationModal } from './RevertConfirmationModal'
import type { GeneralForm } from '../ReservationSettings.zod'

export interface GeneralProps {
  field: Field<GeneralForm>
  data: ReservationWidgetSettingsV2
  languageOptions: SelectOption<LanguageCode>[]
  defaultLanguage: VenueLanguage
}

interface EditOtherLanguagesRowProps {
  field: Field<string>
  onClick: () => void
}
export function EditOtherLanguagesRow({ field, onClick }: EditOtherLanguagesRowProps) {
  const { formatMessage } = useLocales()
  return (
    <HStack justifyContent="space-between">
      <Button data-test="edit-other-languages" variant="tertiary" noPadding onClick={onClick}>
        {formatMessage(generalMessages.brandSettingsEditOtherLanguagesLinkLabel)}
      </Button>
      <FormCharacterCounter field={field} />
    </HStack>
  )
}

export function General({ field, data, languageOptions, defaultLanguage }: GeneralProps) {
  const { venue, venueId } = useVenueContext()
  const { formatMessage } = useLocales()
  const [isLegacyWidgetSettingCollapsed, setIsLegacyWidgetSettingCollapsed] = useState(true)
  const [shouldOpenRevertConfirmationModal, setShouldOpenRevertConfirmationModal] = useState(false)
  const [otherLanguagesModalOpened, setOtherLanguagesModalOpened] = useState(false)
  const { data: languageListData } = useGetVenueLanguagesQuery({
    venueId,
  })
  const {
    defaultLanguage: { value: defaultLanguageValue },
  } = languageListData as LanguageListData
  const nav = useNavigation()
  const resWidgetSpecialAttentionLabel = useWatch(field.prop(`languageStrings.${defaultLanguageValue}.resWidgetSpecialAttentionLabel`))
  const enablePrivateEventsBanner = useWatch(field.prop('enablePrivateEventsBanner'))

  useEffect(() => {
    const { isDirty } = field.prop(`languageStrings.${defaultLanguageValue}.resWidgetSpecialAttentionLabel`).getFieldState()
    if (resWidgetSpecialAttentionLabel && isDirty) {
      field.prop('showSpecialAttentionMessage').set(true)
    }
  }, [defaultLanguageValue, field, resWidgetSpecialAttentionLabel])

  const newResWidgetPrivateEventsEnabled = !!data.general.newResWidgetPrivateEventsEnabled

  const languageStrings = useWatch(field.prop('languageStrings'))
  const [updatedLanguageStrings, setUpdatedLanguageStrings] = useState(languageStrings)

  const onLanguagesModalSaveClick = useCallback(() => {
    field.prop('languageStrings').set(updatedLanguageStrings)
    setOtherLanguagesModalOpened(false)
  }, [field, updatedLanguageStrings])

  const onLanguagesModalCloseClick = useCallback(() => {
    setOtherLanguagesModalOpened(false)
  }, [])

  return (
    <>
      <VStack spacing="lm" mt="lm">
        <BaseSection title={formatMessage(generalMessages.brandSettingsLabel)} padding="xl">
          <VStack spacing="l" width="100%" mt="l" pl="xl" mb="xxl" maxWidth="50%">
            <ImageUploader
              data-test="sr-logo-uploader"
              label={<Text textStyle="body1Bold">{formatMessage(generalMessages.brandSettingsLogoUploaderLabel)}</Text>}
              description={formatMessage(generalMessages.brandSettingsLogoUploaderDescription)}
              field={field.prop('logoImg')}
              variant="normal"
              fullWidth
            />
            <ImageUploader
              data-test="sr-header-uploader"
              label={<Text textStyle="body1Bold">{formatMessage(generalMessages.brandSettingsHeaderUploaderLabel)}</Text>}
              description={formatMessage(generalMessages.brandSettingsHeaderUploaderDescription)}
              field={field.prop('headerImg')}
              variant="normal"
              maxSize={4000000}
              disableEdit
              fullWidth
            />
            <HStack spacing="lm">
              <Box>
                <Label
                  primary={<Text textStyle="body1Bold">{formatMessage(generalMessages.brandSettingsPrimaryColorLabel)}</Text>}
                  secondary={formatMessage(generalMessages.brandSettingsPrimaryColorDescription)}
                />
                <ColorPicker field={field.prop('primaryColor')} data-test="sr-primary-color" />
              </Box>
              <Box>
                <Label
                  primary={<Text textStyle="body1Bold">{formatMessage(generalMessages.brandSettingsSecondaryColorLabel)}</Text>}
                  secondary={formatMessage(generalMessages.brandSettingsSecondaryColorDescription)}
                />
                <ColorPicker fullWidth field={field.prop('secondaryColor')} data-test="sr-secondary-color" />
              </Box>
            </HStack>
            <VStack spacing="xs">
              <Label
                primary={<Text textStyle="body1Bold">{formatMessage(generalMessages.brandSettingsVenueDescriptionFieldLabel)}</Text>}
                secondary={formatMessage(generalMessages.brandSettingsVenueDescriptionFieldDescription)}
              >
                <TextArea
                  field={field.prop(`languageStrings.${defaultLanguageValue}.resWidgetCrossSellVenueLongDescription`)}
                  data-test="sr-res-widget-venue-description"
                  fullWidth
                />
              </Label>
              <EditOtherLanguagesRow
                field={field.prop(`languageStrings.${defaultLanguageValue}.resWidgetCrossSellVenueLongDescription`)}
                onClick={() => setOtherLanguagesModalOpened(true)}
              />
            </VStack>
          </VStack>
        </BaseSection>
        <BaseSection title={formatMessage(generalMessages.optionalMessagingLabel)} padding="xl">
          <VStack spacing="l" width="100%" mt="l" pl="xl" mb="xxl" maxWidth="50%">
            <VStack spacing="xs">
              <Label
                primary={<Text textStyle="body1Bold">{formatMessage(generalMessages.widgetTitle)}</Text>}
                secondary={formatMessage(generalMessages.widgetTitleDescription)}
              >
                <TextArea
                  field={field.prop(`languageStrings.${defaultLanguageValue}.resWidgetTitle`)}
                  data-test="sr-res-widget-title"
                  fullWidth
                />
              </Label>
              <EditOtherLanguagesRow
                field={field.prop(`languageStrings.${defaultLanguageValue}.resWidgetTitle`)}
                onClick={() => setOtherLanguagesModalOpened(true)}
              />
            </VStack>
            <VStack spacing="xs">
              <Text textStyle="body1Bold">{formatMessage(generalMessages.specialAttentionMessageTitle)}</Text>
              <SecondaryText>{formatMessage(generalMessages.specialAttentionMessageDescription)}</SecondaryText>
              <VStack spacing="xs" pt="lm">
                <Label
                  primary={<Text textStyle="body1">{formatMessage(generalMessages.specialAttentionMainHeadlineLabel)}</Text>}
                  secondary={formatMessage(generalMessages.specialAttentionMainHeadlineDescription)}
                >
                  <FormInput
                    field={field.prop(`languageStrings.${defaultLanguageValue}.resWidgetSpecialAttentionLabel`)}
                    data-test="sr-res-widget-special-attention-label"
                    fullWidth
                  />
                </Label>
                <EditOtherLanguagesRow
                  field={field.prop(`languageStrings.${defaultLanguageValue}.resWidgetSpecialAttentionLabel`)}
                  onClick={() => setOtherLanguagesModalOpened(true)}
                />
              </VStack>
              <VStack spacing="xs" pt="lm">
                <Label
                  primary={<Text textStyle="body1">{formatMessage(generalMessages.popupContentLabel)}</Text>}
                  secondary={formatMessage(generalMessages.popupContentDescription)}
                >
                  <TextArea
                    field={field.prop(`languageStrings.${defaultLanguageValue}.resWidgetSpecialAttentionInfoBody`)}
                    data-test="sr-res-widget-special-attention-info-body"
                    fullWidth
                  />
                </Label>
                <EditOtherLanguagesRow
                  field={field.prop(`languageStrings.${defaultLanguageValue}.resWidgetSpecialAttentionInfoBody`)}
                  onClick={() => setOtherLanguagesModalOpened(true)}
                />
              </VStack>
            </VStack>

            <Label primary={<Text textStyle="body1">{formatMessage(generalMessages.addSpecialAttentionMessageOptionalLabel)}</Text>}>
              <VStack spacing="s">
                <Checkbox field={field.prop('showSpecialAttentionMessage')} data-test="sr-res-show-special-attention-message">
                  <Text textStyle="body1">{formatMessage(generalMessages.enableSpecialAttentionSearchAndAvailabilityLabel)}</Text>
                </Checkbox>
                <Checkbox
                  field={field.prop('confirmationIncludeSpecialAttentionMessage')}
                  data-test="sr-res-confirmation-include-special-attention-message"
                >
                  <Text textStyle="body1">{formatMessage(generalMessages.enableSpecialAttentionGuestBookingLabel)}</Text>
                </Checkbox>
              </VStack>
            </Label>

            <VStack spacing="xs">
              <Text fontSize="m" fontWeight="bold">
                {formatMessage(generalMessages.guestFacingLanguageLabel)}
              </Text>
              <SecondaryText>
                {formatMessage(generalMessages.guestFacingLanguageDescription, {
                  link: (
                    <Link
                      to={nav.href(routes.manager2.settings.widgets.reservation, { params: { venueKey: venue.urlKey } })}
                      target="_blank"
                    >
                      {formatMessage(generalMessages.guestFacingLanguagePlacement)}
                      &nbsp;
                      <Icon name="VMSWeb-open-in-new" size="sm" />
                    </Link>
                  ),
                })}
              </SecondaryText>
            </VStack>
          </VStack>
        </BaseSection>
        {newResWidgetPrivateEventsEnabled && (
          <BaseSection title={formatMessage(generalMessages.optionalBannersLabel)} padding="xl">
            <VStack width="100%" mt="l" pl="xl" mb="xxl" maxWidth="50%">
              <Checkbox field={field.prop('enablePrivateEventsBanner')}>
                <VStack>
                  <Box pt="xs" pb="xs">
                    <Text fontWeight="bold">{formatMessage(generalMessages.privateEventsBannerCheckboxTitle)}</Text>
                  </Box>
                  <SecondaryText>{formatMessage(generalMessages.privateEventsBannerCheckboxDescription)}</SecondaryText>
                </VStack>
              </Checkbox>
              {enablePrivateEventsBanner && (
                <VStack spacing="l" pt="lm" pl="lm" ml="xs">
                  <ImageUploader
                    data-test="sr-pe-banner-image"
                    label={formatMessage(generalMessages.privateEventsBannerImageTitle)}
                    description={formatMessage(generalMessages.privateEventsBannerImageDescription)}
                    field={field.prop('bannerImg')}
                    variant="normal"
                    maxSize={2000000}
                    disableEdit
                    fullWidth
                  />
                  <VStack spacing="xs">
                    <Label
                      primary={<Text textStyle="body1">{formatMessage(generalMessages.privateEventsBannerHeadlineTitle)}</Text>}
                      secondary={formatMessage(generalMessages.privateEventsBannerHeadlineDescription)}
                    >
                      <FormInput
                        field={field.prop(`languageStrings.${defaultLanguageValue}.resWidgetPrivateEventsBannerLabel`)}
                        data-test="sr-pe-banner-headline"
                        fullWidth
                      />
                    </Label>
                    {/* temporary disabled for v1 */}
                    {/* <EditOtherLanguagesRow */}
                    {/*   field={field.prop(`languageStrings.${defaultLanguageValue}.resWidgetPrivateEventsBannerLabel`)} */}
                    {/*   onClick={() => setOtherLanguagesModalOpened(true)} */}
                    {/* /> */}
                    <HStack justifyContent="flex-end">
                      <FormCharacterCounter
                        field={field.prop(`languageStrings.${defaultLanguageValue}.resWidgetPrivateEventsBannerLabel`)}
                      />
                    </HStack>
                  </VStack>
                  <VStack spacing="xs">
                    <Label
                      primary={<Text textStyle="body1">{formatMessage(generalMessages.privateEventsBannerCopyTitle)}</Text>}
                      secondary={formatMessage(generalMessages.privateEventsBannerCopyDescription)}
                    >
                      <FormInput
                        field={field.prop(`languageStrings.${defaultLanguageValue}.resWidgetPrivateEventsBannerDescription`)}
                        data-test="sr-pe-banner-copy"
                        fullWidth
                      />
                    </Label>
                    {/* temporary disabled for v1 */}
                    {/* <EditOtherLanguagesRow */}
                    {/*   field={field.prop(`languageStrings.${defaultLanguageValue}.resWidgetPrivateEventsBannerDescription`)} */}
                    {/*   onClick={() => setOtherLanguagesModalOpened(true)} */}
                    {/* /> */}
                    <HStack justifyContent="flex-end">
                      <FormCharacterCounter
                        field={field.prop(`languageStrings.${defaultLanguageValue}.resWidgetPrivateEventsBannerDescription`)}
                      />
                    </HStack>
                  </VStack>
                  <VStack spacing="xs">
                    <Label
                      primary={<Text textStyle="body1">{formatMessage(generalMessages.privateEventsBannerButtonLabelTitle)}</Text>}
                      secondary={formatMessage(generalMessages.privateEventsBannerButtonLabelDescription)}
                    >
                      <FormInput
                        field={field.prop(`languageStrings.${defaultLanguageValue}.resWidgetPrivateEventsBannerExploreButtonLabel`)}
                        data-test="sr-pe-banner-copy"
                        fullWidth
                      />
                    </Label>
                    {/* temporary disabled for v1 */}
                    {/* <EditOtherLanguagesRow */}
                    {/*   field={field.prop(`languageStrings.${defaultLanguageValue}.resWidgetPrivateEventsBannerExploreButtonLabel`)} */}
                    {/*   onClick={() => setOtherLanguagesModalOpened(true)} */}
                    {/* /> */}
                    <HStack justifyContent="flex-end">
                      <FormCharacterCounter
                        field={field.prop(`languageStrings.${defaultLanguageValue}.resWidgetPrivateEventsBannerExploreButtonLabel`)}
                      />
                    </HStack>
                  </VStack>
                </VStack>
              )}
            </VStack>
          </BaseSection>
        )}
        <BaseSection
          title={formatMessage(generalMessages.legacyReservationWidgetLabel)}
          collapsed={isLegacyWidgetSettingCollapsed}
          onToggleCollapse={() => {
            setIsLegacyWidgetSettingCollapsed(!isLegacyWidgetSettingCollapsed)
          }}
          padding="xl"
        >
          <VStack spacing="lm" alignItems="flex-start" m="xl">
            <Text textStyle="body1">{formatMessage(generalMessages.legacyReservationWidgetDesc)}</Text>
            <Button
              variant="tertiary-warning"
              onClick={() => {
                setShouldOpenRevertConfirmationModal(true)
              }}
              data-test="revert-to-legacy-widget-action"
            >
              {formatMessage(generalMessages.legacyReservationWidgetAction)}
            </Button>
          </VStack>
        </BaseSection>
      </VStack>
      <Window active={otherLanguagesModalOpened}>
        <OtherLanguagesModal onClose={onLanguagesModalCloseClick} onSave={onLanguagesModalSaveClick}>
          <GeneralLanguagesTable
            languageStrings={updatedLanguageStrings}
            onChange={setUpdatedLanguageStrings}
            languageOptions={languageOptions}
            defaultLanguage={defaultLanguage}
            // temporary disabled for v1
            // enablePrivateEventsBanner={newResWidgetPrivateEventsEnabled && enablePrivateEventsBanner}
            enablePrivateEventsBanner={enablePrivateEventsBanner}
          />
        </OtherLanguagesModal>
      </Window>
      <Window active={shouldOpenRevertConfirmationModal}>
        <RevertConfirmationModal
          onClose={() => {
            setShouldOpenRevertConfirmationModal(false)
          }}
        />
      </Window>
    </>
  )
}
