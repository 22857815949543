import { ReservationWidgetSettingsService, srPost, srUpload } from '@sevenrooms/core/api'
import {
  GETTING_WIDGET_SETTINGS,
  GET_SUCCESS_WIDGET_SETTINGS,
  GET_FAIL_WIDGET_SETTINGS,
  POSTING_WIDGET_SETTINGS,
  POST_SUCCESS_WIDGET_SETTINGS,
  POST_FAIL_WIDGET_SETTINGS,
  UPDATE_FIELD,
  UPDATE_THEME,
  UPLOAD_PHOTO_PREVIEW,
  UPLOAD_PHOTO_SUCCESS,
  CROP_OPEN,
  CROP_CLOSE,
  CROP_PHOTO,
  CROP_DELETE,
  RESTORE_DEFAULT,
} from './ActionTypes'

// form and app actions

export const updateField = (fieldName, value, error) => ({
  type: UPDATE_FIELD,
  fieldName,
  value,
  error,
})

export const updateTheme = () => ({ type: UPDATE_THEME })

// Actions to retrieve widget settings

export const gettingWidgetSettings = () => ({ type: GETTING_WIDGET_SETTINGS })

export const restoreDefault = () => ({ type: RESTORE_DEFAULT })

export const getSuccessWidgetSettings = widgetSettings => ({
  type: GET_SUCCESS_WIDGET_SETTINGS,
  widgetSettings,
})

export const getFailWidgetSettings = () => ({ type: GET_FAIL_WIDGET_SETTINGS })

export const fetchWidgetSettings = (venueId, errHandler) =>
  ReservationWidgetSettingsService.getReservationWidgetSettings({ venueId }).catch(err => errHandler?.(err))

export const tryGetWidgetSettings = venueId => dispatch => {
  dispatch(gettingWidgetSettings())
  const errHandler = () => dispatch(getFailWidgetSettings())
  fetchWidgetSettings(venueId, errHandler).then(data =>
    dispatch(
      getSuccessWidgetSettings({
        venueId,
        ...data.widgetSettings,
        backgroundImgCropData:
          !data.widgetSettings.backgroundImgCropData || Object.keys(data.widgetSettings.backgroundImgCropData).length === 0
            ? null
            : data.widgetSettings.backgroundImgCropData,
        headerImgCropData:
          !data.widgetSettings.headerImgCropData || Object.keys(data.widgetSettings.headerImgCropData).length === 0
            ? null
            : data.widgetSettings.headerImgCropData,
        lightTheme: data.lightTemplate,
        darkTheme: data.darkTemplate,
        isSmsMarketingEnabled: data.isSmsMarketingEnabled,
        newResWidgetEnabled: data.newResWidgetEnabled,
      })
    )
  )
}

// Actions to save widget settings

export const postingWidgetSettings = () => ({ type: POSTING_WIDGET_SETTINGS })

export const postSuccessWidgetSettings = () => ({
  type: POST_SUCCESS_WIDGET_SETTINGS,
})

export const postFailWidgetSettings = () => ({
  type: POST_FAIL_WIDGET_SETTINGS,
})

export const tryPostWidgetSettings = widgetSettings => dispatch => {
  dispatch(postingWidgetSettings())
  const errHandler = () => dispatch(postFailWidgetSettings())
  const {
    venueId,
    // in the widget settings reducer we store info that isn't actually part of the widget settings
    darkTheme: _darkTheme,
    lightTheme: _lightTheme,
    isSmsMarketingEnabled: _isSmsMarketingEnabled,
    newResWidgetEnabled: _newResWidgetEnabled,
    // we strip these out because it causes issues in the backend code, and it looks like we're  not really supposed to send it
    headerImgPhotoDict: _headerDict,
    backgroundImgPhotoDict: _backgroundDict,
    ...newWidgetSettings
  } = widgetSettings
  ReservationWidgetSettingsService.setReservationWidgetSettings({ venueId, widgetSettings: newWidgetSettings })
    .catch(errHandler)
    .then(() => dispatch(postSuccessWidgetSettings()))
}

export const uploadPhotoPreview = (imageType, preview) => ({
  type: UPLOAD_PHOTO_PREVIEW,
  imageType,
  preview,
})

export const uploadPhotoSuccess = (imageType, upload) => ({
  type: UPLOAD_PHOTO_SUCCESS,
  imageType,
  upload,
})

export const uploadPhoto = (imageType, file) => dispatch => {
  if (!['image/jpeg', 'image/png', 'image/gif', 'image/tiff'].includes(file.type)) {
    window.Interface._alert('Image type not acceptable')
    return undefined
  }
  dispatch(uploadPhotoPreview(imageType, file.preview))
  dispatch(cropOpen(imageType))

  return srPost('/upload-url', { rurl: Math.random().toString() }, { convertToGetParams: true })
    .then(response => (response.error ? response : srUpload(response.upload_url, file)))
    .then(uploadData => {
      dispatch(uploadPhotoSuccess(imageType, uploadData))
    })
}

export const cropOpen = imageType => ({ type: CROP_OPEN, imageType })

export const cropImage = (imageType, coords) => ({
  type: CROP_PHOTO,
  imageType,
  coords,
})

export const closeCrop = imageType => ({ type: CROP_CLOSE, imageType })

export const cropDelete = imageType => ({ type: CROP_DELETE, imageType })
