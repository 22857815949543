import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { HStack, VStack, Image, Box, ModalBody, ModalFooter, ModalActions } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { ContentBackground } from './ContentBackground'
import { newResWidgetBannerMessages } from './NewResWidgetBanner.messages'

const { mediaUrl } = window.globalInit

interface IntroContentProp {
  onClose: () => void
  onComplete: () => void
}
export function IntroContent({ onClose, onComplete }: IntroContentProp) {
  const { formatMessage } = useLocales()
  return (
    <>
      <ModalBody pl="3xl" pr="3xl">
        <HStack justifyContent="center" spacing="3xl">
          <VStack width="300px" mt="l">
            <Text textStyle="h2">{formatMessage(newResWidgetBannerMessages.introTitle)}</Text>
            <Box mt="s">
              <Text textStyle="body1">{formatMessage(newResWidgetBannerMessages.introDescription)}</Text>
            </Box>
            <HStack mt="lm" spacing="s" alignItems="flex-start">
              <Image src={`${mediaUrl}images/manager/settings/reswidget/wand.svg`} alt="wand" />
              <VStack spacing="xs">
                <Text textStyle="body1Bold">{formatMessage(newResWidgetBannerMessages.introDesignSectionTitle)}</Text>
                <Text textStyle="body1">{formatMessage(newResWidgetBannerMessages.introDesignSectionDescription)}</Text>
              </VStack>
            </HStack>
            <HStack mt="lm" spacing="s" alignItems="flex-start">
              <Image src={`${mediaUrl}images/manager/settings/reswidget/phone-with-check.svg`} alt="phone" />
              <VStack spacing="xs">
                <Text textStyle="body1Bold">{formatMessage(newResWidgetBannerMessages.introOptimizedSectionTitle)}</Text>
                <Text textStyle="body1">{formatMessage(newResWidgetBannerMessages.introOptimizedSectionDescription)}</Text>
              </VStack>
            </HStack>
            <HStack mt="lm" spacing="s" alignItems="flex-start">
              <Image src={`${mediaUrl}images/manager/settings/reswidget/bills.svg`} alt="money" />
              <VStack spacing="xs">
                <Text textStyle="body1Bold">{formatMessage(newResWidgetBannerMessages.introImprovedSectionTitle)}</Text>
                <Text textStyle="body1">{formatMessage(newResWidgetBannerMessages.introImprovedSectionDescription)}</Text>
              </VStack>
            </HStack>
          </VStack>
          <ContentBackground borderRadius="m" p="none lm" m="l none" spacing="lm" alignItems="center" justifyContent="center">
            <Image width="125px" height="269px" src={`${mediaUrl}images/manager/settings/reswidget/fivefigs.png`} alt="five figs" />
            <Image width="125px" height="269px" src={`${mediaUrl}images/manager/settings/reswidget/fourolives.png`} alt="four olives" />
            <Image width="125px" height="269px" src={`${mediaUrl}images/manager/settings/reswidget/threepears.png`} alt="three pairs" />
          </ContentBackground>
        </HStack>
      </ModalBody>
      <ModalFooter borderTopColor="borders" borderWidth="s" p="s sm">
        <ModalActions>
          <Button data-test="remind-later-button" variant="secondary" onClick={onClose}>
            {formatMessage(newResWidgetBannerMessages.introRemindLaterBtn)}
          </Button>
          <Button data-test="setup-now-button" variant="primary" onClick={onComplete}>
            {formatMessage(newResWidgetBannerMessages.introSetupNowBtn)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </>
  )
}
