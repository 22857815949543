import { type FormEvent, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import type { LanguageCode, LanguageListData } from '@sevenrooms/core/api'
import type { LanguageStrings } from '@sevenrooms/core/domain'
import { useWatch, type Field } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Select, StyledTarget } from '@sevenrooms/core/ui-kit/core'
import { StyledTextArea, TextArea, Button } from '@sevenrooms/core/ui-kit/form'
import { HStack, VStack, Box, Flex, Image, ModalBody } from '@sevenrooms/core/ui-kit/layout'
import { List, ListItem, Text } from '@sevenrooms/core/ui-kit/typography'
import { CharacterCounter, FormCharacterCounter } from '@sevenrooms/mgr-settings-reservation-widget/FormCharacterCounter'
import { EditOtherLanguagesRow } from '@sevenrooms/mgr-settings-reservation-widget/General/General'
import { newResWidgetBannerMessages } from '../NewResWidgetBanner.messages'
import { NewResWidgetModalFooter } from '../NewResWidgetModalFooter'

const { mediaUrl } = window.globalInit

interface ReviewVenueDescriptionStepProp {
  stepIndex: number
  languageListData: LanguageListData
  field: Field<LanguageStrings>
  onComplete: () => void
  onPrevious: () => void
}

export function ReviewVenueDescriptionStep({ field, stepIndex, languageListData, onComplete, onPrevious }: ReviewVenueDescriptionStepProp) {
  const { formatMessage } = useLocales()
  const [editOtherLanguagesOpened, setOtherLanguagesOpened] = useState(false)
  const languageStrings = useWatch(field)
  const {
    defaultLanguage: { value: defaultLanguageValue },
    enabledLanguages,
  } = languageListData

  const languageOptions = useMemo(
    () =>
      enabledLanguages.map(language => ({
        id: language.value as LanguageCode,
        label: language.name,
      })),
    [enabledLanguages]
  )

  const onLanguageStringChange = useCallback(
    (value: string, language: LanguageCode) => {
      field.set({
        ...languageStrings,
        [language]: {
          ...languageStrings[language],
          resWidgetCrossSellVenueLongDescription: value,
        },
      })
    },
    [field, languageStrings]
  )

  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions[0]?.id)
  const crossSellVenueLongDescriptionValue = selectedLanguage
    ? languageStrings[selectedLanguage].resWidgetCrossSellVenueLongDescription
    : ''

  return (
    <>
      <ModalBody pl="3xl" pr="3xl">
        <HStack justifyContent="center" spacing="3xl">
          <VStack spacing="lm" width="475px" pt="l" pb="l">
            <Text textStyle="h2">{formatMessage(newResWidgetBannerMessages.reviewVenueDescriptionStepTitle)}</Text>
            <VStack spacing="xs">
              <TextArea
                field={field.prop(`${defaultLanguageValue}.resWidgetCrossSellVenueLongDescription`)}
                data-test="sr-res-widget-venue-description"
                fullWidth
              />
              {selectedLanguage ? (
                <EditOtherLanguagesRow
                  field={field.prop(`${defaultLanguageValue}.resWidgetCrossSellVenueLongDescription`)}
                  onClick={() => setOtherLanguagesOpened(true)}
                />
              ) : (
                <Flex alignSelf="flex-end">
                  <FormCharacterCounter field={field.prop(`${defaultLanguageValue}.resWidgetCrossSellVenueLongDescription`)} />
                </Flex>
              )}
            </VStack>
            {editOtherLanguagesOpened && selectedLanguage && (
              <VStack spacing="xs" alignItems="flex-end">
                <StyledStringSelectionContainer width="100%">
                  <Select
                    options={languageOptions}
                    value={selectedLanguage}
                    onChange={value => setSelectedLanguage(value as LanguageCode)}
                    searchable={false}
                  />
                  <TextArea
                    value={crossSellVenueLongDescriptionValue}
                    data-test="sr-res-widget-venue-description"
                    fullWidth
                    onChange={(e: FormEvent<HTMLTextAreaElement>) => {
                      onLanguageStringChange(e.currentTarget.value, selectedLanguage)
                    }}
                  />
                </StyledStringSelectionContainer>
                <CharacterCounter value={crossSellVenueLongDescriptionValue} />
              </VStack>
            )}
          </VStack>
          <VStack p="l" spacing="lm" backgroundColor="secondaryBackground" borderRadius="m" m="l none" width="300px" height="420px">
            <Text textStyle="body1Bold">{formatMessage(newResWidgetBannerMessages.reviewVenueDescriptionStepBestPracticesTitle)}</Text>
            <VStack spacing="sm">
              <Text textStyle="body2Medium" textTransform="uppercase" color="attentionNew" letterSpacing="m">
                {formatMessage(newResWidgetBannerMessages.reviewVenueDescriptionStepBestPracticesPlacementTitle)}
              </Text>
              <Image
                width="236px"
                height="121px"
                src={`${mediaUrl}images/manager/settings/reswidget/placement_venue_description.png`}
                alt="placememnt venue description"
              />
            </VStack>
            <VStack spacing="sm">
              <Text textStyle="body2Medium" textTransform="uppercase" color="attentionNew" letterSpacing="m">
                {formatMessage(newResWidgetBannerMessages.reviewVenueDescriptionStepBestPracticesInfoTitle)}
              </Text>
              <List spacing="xs">
                <ListItem>
                  <Text textStyle="body1">
                    {formatMessage(newResWidgetBannerMessages.reviewVenueDescriptionStepBestPracticesInfoBullet1)}
                  </Text>
                </ListItem>
                <ListItem>
                  <Text textStyle="body1">
                    {formatMessage(newResWidgetBannerMessages.reviewVenueDescriptionStepBestPracticesInfoBullet2)}
                  </Text>
                </ListItem>
              </List>
            </VStack>
          </VStack>
        </HStack>
      </ModalBody>
      <NewResWidgetModalFooter
        onPrevious={onPrevious}
        stepIndex={stepIndex}
        actions={
          <Button variant="primary" onClick={onComplete} data-test="sr-continue-button">
            {formatMessage(commonMessages.continue)}
          </Button>
        }
      />
    </>
  )
}

export const StyledStringSelectionContainer = styled(Box)`
  ${StyledTarget} {
    border-radius: 4px 4px 0 0;
  }
  ${StyledTextArea} {
    &:not(:focus) {
      border-top: 0;
    }
    border-radius: 0 0 4px 4px;
  }
`
