import { useState } from 'react'
import { Button, IconButton } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { HStack, VStack, Flex, Image, Box, ModalBody, ModalFooter } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { ContentBackground } from './ContentBackground'

const { venueUrlKey, mediaUrl } = window.globalInit
const newResWidgetUrl = `https://${window.location.host}/explore/${venueUrlKey}/reservations/create/search`

interface PreviewWidgetContentProp {
  onPreview: () => void
  onComplete: () => void
  onPrevious: () => void
}
export function PreviewWidgetContent({ onPreview, onComplete, onPrevious }: PreviewWidgetContentProp) {
  const [hasCopied, setHasCopied] = useState(false)

  const onCopy = () => {
    navigator.clipboard.writeText(newResWidgetUrl)
    setHasCopied(true)
    setTimeout(() => {
      setHasCopied(false)
    }, 4000)
  }

  return (
    <>
      <ModalBody pl="3xl" pr="3xl">
        <HStack justifyContent="center" spacing="m">
          <VStack spacing="s" width="300px">
            <VStack spacing="s" width="290px" mt="l">
              <Text textStyle="h2">But first, take a peek</Text>
              <Text textStyle="body1">Your new widget is pre-filled with existing settings, so it’s nearly finished.</Text>
              <Text textStyle="body1">To see it on mobile, copy the link and and open it on your phone.</Text>
            </VStack>
            <HStack mt="lm">
              <Button data-test="preview-new-widget" variant="secondary" onClick={onPreview}>
                Preview New Widget&nbsp;
                <Icon name="VMSWeb-open-in-new" size="lg" />
              </Button>
              <Button data-test="copy-link" variant="tertiary" onClick={onCopy}>
                {hasCopied ? 'Link Copied!' : 'Copy Link'}
              </Button>
            </HStack>
          </VStack>
          <ContentBackground borderRadius="m" p="l" m="l none" alignItems="flex-start">
            <Image
              width="324px"
              height="220px"
              src={`${mediaUrl}images/manager/settings/reswidget/fivefigs-desktop.png`}
              alt="five figs desktop"
            />
            <Box position="relative" top="3xl" right="xl">
              <Image
                width="125px"
                height="269px"
                src={`${mediaUrl}images/manager/settings/reswidget/fivefigs.png`}
                alt="five figs mobile"
              />
            </Box>
          </ContentBackground>
        </HStack>
      </ModalBody>
      <ModalFooter borderTopColor="borders" borderWidth="s" p="s sm">
        <Flex justifyContent="space-between">
          <IconButton
            size="m"
            borderType="none-round"
            icon="VMSWeb-chevron-left"
            iconColor="primaryIcons"
            onClick={onPrevious}
            aria-label="back"
          />
          <Button data-test="continue-button" variant="primary" onClick={onComplete}>
            Continue
          </Button>
        </Flex>
      </ModalFooter>
    </>
  )
}
