import { useSetReservationBrandSettingsMutation } from '@sevenrooms/core/api'
import type { UseForm } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { ColorPicker, ImageUploader, Label, Button } from '@sevenrooms/core/ui-kit/form'
import { HStack, VStack, Box, notify, Image, ModalBody } from '@sevenrooms/core/ui-kit/layout'
import { List, ListItem, Text } from '@sevenrooms/core/ui-kit/typography'
import { newResWidgetBannerMessages } from '../NewResWidgetBanner.messages'
import { NewResWidgetModalFooter } from '../NewResWidgetModalFooter'
import type { ReservationSettingsForm } from '../ReservationSettings.zod'
import type { z } from 'zod'

const { venueId, mediaUrl } = window.globalInit

interface OtherBrandSettingsStepProp {
  stepIndex: number
  rhForm: UseForm<z.ZodSchema<ReservationSettingsForm>>
  onComplete: () => void
  onPrevious: () => void
}
export function OtherBrandSettingsStep({ stepIndex, rhForm, onComplete, onPrevious }: OtherBrandSettingsStepProp) {
  const { formatMessage } = useLocales()
  const {
    field,
    handleSubmit,
    formState: { dirtyFields },
  } = rhForm

  const [setReservationBrandSettings, { isLoading: isSaving }] = useSetReservationBrandSettingsMutation()

  const handleSave = () => {
    handleSubmit(async (formData: ReservationSettingsForm) => {
      try {
        await setReservationBrandSettings({
          venueId,
          formData,
          uploadLogoImage: !!dirtyFields.logoImg,
          uploadHeaderImage: !!dirtyFields.headerImg,
        }).unwrap()
        notify({ content: formatMessage(newResWidgetBannerMessages.success), type: 'success' })
        onComplete()
      } catch (error) {
        notify({ content: formatMessage(newResWidgetBannerMessages.error), type: 'error' })
      }
    })()
  }

  return (
    <>
      <ModalBody pl="3xl" pr="3xl">
        <HStack justifyContent="center" spacing="3xl">
          <VStack spacing="lm" width="475px" pt="l" pb="l">
            <Text textStyle="h2">{formatMessage(newResWidgetBannerMessages.otherBrandSettingsStepTitle)}</Text>
            <ImageUploader
              data-test="sr-logo-uploader"
              label={<Text textStyle="body1Bold">{formatMessage(newResWidgetBannerMessages.otherBrandSettingsStepHeaderLogoTitle)}</Text>}
              field={field.prop('logoImg')}
              variant="small"
              fullWidth
            />
            <HStack spacing="lm">
              <Box>
                <Label
                  primary={
                    <Text textStyle="body1Bold">{formatMessage(newResWidgetBannerMessages.otherBrandSettingsStepPrimaryColorTitle)}</Text>
                  }
                />
                <ColorPicker fullWidth captureCloseClick field={field.prop('primaryColor')} data-test="sr-primary-color" />
              </Box>
              <Box>
                <Label
                  primary={
                    <Text textStyle="body1Bold">{formatMessage(newResWidgetBannerMessages.otherBrandSettingsStepSecondaryColorTitle)}</Text>
                  }
                />
                <ColorPicker fullWidth captureCloseClick field={field.prop('secondaryColor')} data-test="sr-secondary-color" />
              </Box>
            </HStack>
          </VStack>
          <VStack p="l" spacing="lm" backgroundColor="secondaryBackground" borderRadius="m" m="l none" width="300px">
            <Text textStyle="body1Bold">{formatMessage(newResWidgetBannerMessages.otherBrandSettingsStepBestPracticesTitle)}</Text>
            <VStack spacing="xs">
              <Text textStyle="body2Medium" textTransform="uppercase" color="attentionNew" letterSpacing="m">
                {formatMessage(newResWidgetBannerMessages.otherBrandSettingsStepBestPracticesPlacementTitle)}
              </Text>
              <Image
                width="236px"
                height="121px"
                src={`${mediaUrl}images/manager/settings/reswidget/placement_logo_colors.png`}
                alt="placememnt logo and colors"
              />
            </VStack>
            <VStack spacing="sm">
              <Text textStyle="body2Medium" textTransform="uppercase" color="attentionNew" letterSpacing="m">
                {formatMessage(newResWidgetBannerMessages.otherBrandSettingsStepBestPracticesLogoGuidelinesTitle)}
              </Text>
              <List spacing="xs">
                <ListItem>
                  <Text textStyle="body1">
                    {formatMessage(newResWidgetBannerMessages.otherBrandSettingsStepBestPracticesLogoGuidelinesBullet1)}
                  </Text>
                </ListItem>
                <ListItem>
                  <Text textStyle="body1">
                    {formatMessage(newResWidgetBannerMessages.otherBrandSettingsStepBestPracticesLogoGuidelinesBullet2)}
                  </Text>
                </ListItem>
                <ListItem>
                  <Text textStyle="body1">
                    {formatMessage(newResWidgetBannerMessages.otherBrandSettingsStepBestPracticesLogoGuidelinesBullet3)}
                  </Text>
                </ListItem>
              </List>
            </VStack>
            <VStack spacing="sm">
              <Text textStyle="body2Medium" textTransform="uppercase" color="attentionNew" letterSpacing="m">
                {formatMessage(newResWidgetBannerMessages.otherBrandSettingsStepBestPracticesPrimaryColorTitle)}
              </Text>
              <Text textStyle="body1">
                {formatMessage(newResWidgetBannerMessages.otherBrandSettingsStepBestPracticesPrimaryColorContent)}
              </Text>
            </VStack>
            <VStack spacing="sm">
              <Text textStyle="body2Medium" textTransform="uppercase" color="attentionNew" letterSpacing="m">
                {formatMessage(newResWidgetBannerMessages.otherBrandSettingsStepBestPracticesSecondaryColorTitle)}
              </Text>
              <Text textStyle="body1">
                {formatMessage(newResWidgetBannerMessages.otherBrandSettingsStepBestPracticesSecondaryColorContent)}
              </Text>
            </VStack>
          </VStack>
        </HStack>
      </ModalBody>
      <NewResWidgetModalFooter
        stepIndex={stepIndex}
        onPrevious={onPrevious}
        actions={
          <Box width="152px">
            <Button variant="primary" onClick={handleSave} data-test="sr-continue-button" disabled={isSaving} fullWidth>
              {isSaving
                ? formatMessage(newResWidgetBannerMessages.otherBrandSettingsStepSaving)
                : formatMessage(newResWidgetBannerMessages.otherBrandSettingsStepSaveAndContinue)}
            </Button>
          </Box>
        }
      />
    </>
  )
}
