import { useMemo } from 'react'
import type { LanguageStrings } from '@sevenrooms/core/domain'
import { z } from '@sevenrooms/core/form'
import { useImageForm } from '@sevenrooms/core/ui-kit/form'

export function useReservationSettingsForm() {
  const image = useImageForm()
  return useMemo(
    () =>
      z.object({
        languageStrings: z.custom<LanguageStrings>(),
        logoImg: image.nullable(),
        headerImg: image.nullable(),
        primaryColor: z.string(),
        secondaryColor: z.string(),
      }),
    [image]
  )
}

export type ReservationSettingsForm = z.infer<ReturnType<typeof useReservationSettingsForm>>
