// /* @flow */
import _ from 'lodash'
import PropTypes from 'prop-types'
import * as React from 'react'
import styled from 'styled-components'
import RadioLine from '../RadioLine'

/* type Option = {
  value: string,
  label: string,
  children?: React.Node,
  alwaysShowChild: boolean,
} */

/* type Props = {
  onClick: (value: string, field: string) => mixed,
  field: string,
  options: Array<Option>,
  selectedValue?: string,
  listLabel?: string,
  dataTest?: string,
  customRadioListWrapper: Array<string | (() => mixed)>,
  customRadioListLabel: Array<string | (() => mixed)>,
  customRadioWrapper: Array<string | (() => mixed)>,
  customRadioIcon: Array<string | (() => mixed)>,
  customRadioCheck: Array<string | (() => mixed)>,
  customRadioLabel: Array<string | (() => mixed)>,
} */

class RadioList extends React.Component /* <Props> */ {
  radioGroupId = `sr-radiogroup-${this.props.field}`

  clickHandlerMap = _.map(this.props.options, option => this.props.onClick.bind(this, option.value, this.props.field))

  radioIdMap = _.map(this.props.options, option => `sr-radio-${this.props.field}-${option.value}`)

  render() {
    return (
      <RadioListWrapper
        id={this.radioGroupId}
        role="radiogroup"
        customRadioListWrapper={this.props.customRadioListWrapper}
        data-test={this.props.dataTest}
      >
        {this.props.listLabel && (
          <RadioListLabel htmlFor={this.radioGroupId} customRadioListLabel={this.props.customRadioListLabel}>
            {this.props.listLabel}
          </RadioListLabel>
        )}
        {_.map(this.props.options, (option, idx) => {
          const isSelected = this.props.selectedValue === option.value
          const radioId = this.radioIdMap[idx]
          return (
            <RadioLine
              key={radioId}
              radioId={radioId}
              value={option.value}
              label={option.label}
              onClick={!isSelected ? this.clickHandlerMap[idx] : undefined}
              isSelected={isSelected}
              customRadioWrapper={this.props.customRadioWrapper}
              customRadioIcon={this.props.customRadioIcon}
              customRadioCheck={this.props.customRadioCheck}
              customRadioLabel={this.props.customRadioLabel}
              tip={option.tip}
              name={option.name}
            >
              {(isSelected || option.alwaysShowChild) && option.child}
            </RadioLine>
          )
        })}
      </RadioListWrapper>
    )
  }
}

RadioList.defaultProps = {
  customRadioListWrapper: [],
  customRadioListLabel: [],
  customRadioWrapper: [],
  customRadioIcon: [],
  customRadioCheck: [],
  customRadioLabel: [],
}

const RadioListWrapper = styled.div`
  ${props => props.customRadioListWrapper};
`

const RadioListLabel = styled.label`
  display: inline-block;
  font-size: 15px;
  font-family: inherit;
  ${props => props.customRadioListLabel};
`

RadioList.propTypes = {
  customRadioListWrapper: PropTypes.array,
  customRadioListLabel: PropTypes.array,
  customRadioWrapper: PropTypes.array,
  customRadioIcon: PropTypes.array,
  customRadioCheck: PropTypes.array,
  customRadioLabel: PropTypes.array,
  onClick: PropTypes.any,
  field: PropTypes.any,
  options: PropTypes.any,
  dataTest: PropTypes.any,
  listLabel: PropTypes.string,
  selectedValue: PropTypes.string,
}

export default RadioList
