import { useState } from 'react'
import { ReservationWidgetSettingsServiceV2 } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { Button, IconButton } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { HStack, VStack, Flex, Image, Box, notify, ModalBody, ModalFooter, ModalActions } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { ContentBackground } from './ContentBackground'
import { newResWidgetBannerMessages } from './NewResWidgetBanner.messages'

const { mediaUrl, venueId } = window.globalInit

interface LaunchWidgetContentProp {
  onComplete: () => void
  onPreview: () => void
  onPrevious: () => void
}
export function LaunchWidgetContent({ onComplete, onPreview, onPrevious }: LaunchWidgetContentProp) {
  const { formatMessage } = useLocales()
  const [isLaunching, setIsLaunching] = useState(false)

  const launch = async () => {
    setIsLaunching(true)
    try {
      await ReservationWidgetSettingsServiceV2.setReservationWidgetRedirect(venueId, true)
    } catch (e) {
      setIsLaunching(false)
      notify({ content: 'An error occurred while trying to launch the new widget. Please try again.', type: 'error' })
      return
    }

    onComplete()
  }

  return (
    <>
      <ModalBody pl="3xl" pr="3xl">
        <HStack justifyContent="center" spacing="3xl">
          <VStack spacing="lm" width="300px" mt="l">
            <Text textStyle="h2">You’re ready to launch!</Text>
            <VStack spacing="s">
              <Text textStyle="body1Bold">One-click switch from legacy to new</Text>
              <Text textStyle="body1">
                When you launch, all links to your legacy widget will instantly and automatically be replaced with your new widget URL.
              </Text>
            </VStack>
            <VStack spacing="s">
              <Text textStyle="body1Bold">Settings are automatically inherited</Text>
              <Text textStyle="body1">
                The new widget uses the Search & Availability and Checkout & Confirmation settings of the legacy widget, so the switch is
                seamless!
              </Text>
            </VStack>
            <VStack spacing="s">
              <Text textStyle="body1Bold">You can always go back</Text>
              <Text textStyle="body1">
                Rest assured, you can revert back to your legacy widget at any point. Check out the help center for answers to any of your
                questions.
              </Text>
            </VStack>
          </VStack>
          <ContentBackground borderRadius="m" p="xxl none none none" m="l none" justifyContent="center">
            <Image src={`${mediaUrl}images/manager/settings/reswidget/fivefigs-animated.gif`} alt="five figs" />
          </ContentBackground>
        </HStack>
      </ModalBody>
      <ModalFooter borderTopColor="borders" borderWidth="s" p="s sm">
        <Flex justifyContent="space-between">
          <IconButton
            size="m"
            borderType="none-round"
            icon="VMSWeb-chevron-left"
            iconColor="primaryIcons"
            disabled={isLaunching}
            onClick={onPrevious}
            aria-label="back"
          />
          <ModalActions>
            <Button variant="secondary" data-test="sr-preview-button" disabled={isLaunching} onClick={onPreview}>
              {formatMessage(newResWidgetBannerMessages.launchWidgetContentStepPreviewButton)}&nbsp;
              <Icon name="VMSWeb-open-in-new" size="lg" />
            </Button>
            <Box width="126px">
              <Button variant="primary" data-test="sr-launch-button" fullWidth disabled={isLaunching} onClick={launch}>
                {formatMessage(
                  isLaunching
                    ? newResWidgetBannerMessages.launchWidgetContentStepLaunchLoadingButton
                    : newResWidgetBannerMessages.launchWidgetContentStepLaunchButton
                )}
              </Button>
            </Box>
          </ModalActions>
        </Flex>
      </ModalFooter>
    </>
  )
}
