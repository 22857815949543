import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { HStack, Loader, ModalBody } from '@sevenrooms/core/ui-kit/layout'
import { NewResWidgetModalFooter } from '../NewResWidgetModalFooter'

export function LoaderStep() {
  const { formatMessage } = useLocales()

  return (
    <>
      <ModalBody pl="xxl" pr="xxl">
        <HStack justifyContent="center" spacing="xxl">
          <Loader />
        </HStack>
      </ModalBody>
      <NewResWidgetModalFooter
        disabled
        actions={
          <Button variant="primary" disabled data-test="continue-button">
            {formatMessage(commonMessages.continue)}
          </Button>
        }
      />
    </>
  )
}
