import { defineMessages } from '@sevenrooms/core/locales'

export const otherLanguagesModalMessages = defineMessages({
  otherLanguagesModalHeaderLabel: {
    id: 'otherLanguagesModalHeaderLabel',
    defaultMessage: 'Guest-Facing Language in the Reservation Widget',
  },
  otherLanguagesModalHeaderDescription: {
    id: 'otherLanguagesModalHeaderDescription',
    defaultMessage: 'Edit any other copy in <link>Reservation Widget Guest-Facing Language</link>',
  },
})
